import React, { useEffect, useRef } from 'react';
import { Animated, Easing } from 'react-native';
import Ge from '../svg/Ge';
import { getColor } from '../utils/theme';

interface IGeSpinnerProps {
    size?: number;
    color?: string;
}

export const GeSpinner = (props: IGeSpinnerProps) => {
    const { size = 50, color = getColor('primary') } = props;

    // Create a simple infinite spin animation using Animated API in React Native

    const spinValue = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        Animated.loop(
            Animated.timing(spinValue, {
                toValue: 1,
                duration: 750,
                easing: Easing.linear,
                useNativeDriver: true,
            }),
        ).start();
    }, [spinValue]);

    const spin = spinValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['360deg', '0deg'],
    });

    return (
        <Animated.View
            style={{
                transform: [{ rotate: spin }],
            }}
        >
            <Ge
                fill={color}
                width={size}
                height={size}
            />
        </Animated.View>
    );
};

export default GeSpinner;
