import { View, Text, Pressable } from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { APP_EVENT_ID, FONT_MEDIUM, getColor, initTheme } from '../utils/theme';
import { width } from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import AsyncStorage from '@react-native-async-storage/async-storage';
//import Keychain from 'react-native-keychain';
import User from '../object/User';
import { Header } from '../component/Header';
import { PastilleView } from '../component/PastilleView';
import {
    ArrowRightOnRectangleIcon,
    LanguageIcon,
    LockClosedIcon,
    NoSymbolIcon,
    TrashIcon,
    UserGroupIcon,
    UserIcon,
    WrenchScrewdriverIcon,
} from 'react-native-heroicons/solid';
import Bonheur from '../svg/Bonheur';
import UserChat from '../object/compressed/chat/UserChat';
import UserConnect from '../object/compressed/chat/UserConnect';
//import { storage } from '../../App';
import { CommonActions } from '@react-navigation/native';
import { Use } from 'react-native-svg';

export const ParamModal = (props: IModalProps) => {
    const { t } = useTranslation();
    User.translation = t;

    const actionList = [];

    {
        /*actionList.push({
        label: 'events',
        onClick: (navigation: any) => {
            props.navigation.dispatch(
                CommonActions.reset({
                    index: 0,
                    routes: [{ name: 'Event' }],
                }),
            );
            User._instance.inviteList.setList([]);
        },
        icon: (
            <Bonheur
                fill={'white'}
                height={32}
                width={32}
            />
        ),
    });*/
    }
    if (!User._instance.nothing) {
        actionList.push({
            label: 'block_list',
            onClick: (navigation: any) => {
                navigation.navigate('BlockedListModal');
            },
            icon: (
                <NoSymbolIcon
                    color={'white'}
                    size={22}
                />
            ),
        });
    }
    if (!User._instance.nothing) {
        actionList.push({
            label: 'password',
            onClick: (navigation: any) => {
                navigation.navigate('PasswordModal');
            },
            icon: (
                <LockClosedIcon
                    color={'white'}
                    size={22}
                />
            ),
        });
    }
    actionList.push({
        label: 'lang',
        onClick: (navigation: any) => {
            navigation.navigate('LanguageModal');
        },
        icon: (
            <LanguageIcon
                color={'white'}
                size={22}
            />
        ),
    });
    if (!User._instance.nothing) {
        actionList.push({
            label: 'privacy',
            onClick: (navigation: any) => {
                navigation.navigate('ConfidentialModal');
            },
            icon: (
                <WrenchScrewdriverIcon
                    color={'white'}
                    size={20}
                />
            ),
        });
    }
    if (User._instance.nothing) {
        actionList.push({
            label: 'login',
            onClick: (navigation: any) => {
                navigation.navigate('Login');
            },
            icon: (
                <UserIcon
                    color={'white'}
                    size={22}
                />
            ),
        });
        actionList.push({
            label: 'register',
            onClick: (navigation: any) => {
                navigation.navigate('Inscrip');
            },
            icon: (
                <UserIcon
                    color={'white'}
                    size={22}
                />
            ),
        });
    }
    if (!User._instance.nothing) {
        actionList.push({
            label: 'logout',
            onClick: (navigation: any) => {
                /*Keychain.setInternetCredentials(
                    'happlyevent.access_token',
                    'happlyevent',
                    'del',
                );
                Keychain.setInternetCredentials(
                    'happlyevent.refresh_token',
                    'happlyevent',
                    'del',
                );*/
                //storage.setMap('user', {});
                User._instance = new User();
                User._instanceChat = new UserChat();
                User._instanceConnect = new UserConnect();
                AsyncStorage.removeItem('event');
                navigation.dispatch(
                    CommonActions.reset({
                        index: 0,
                        routes: [{ name: 'BeforeLogin' }],
                    }),
                );
            },
            icon: (
                <ArrowRightOnRectangleIcon
                    color={'white'}
                    size={22}
                />
            ),
        });
    }

    if (User._instance.nothing) {
        actionList.push({
            label: 'delete_account',
            color: getColor('primary'),
            icon: (
                <TrashIcon
                    fill={'white'}
                    height={20}
                    width={20}
                />
            ),
            onClick: (navigation: any) => {
                navigation.navigate('DeleteUserModal');
            },
        });
    }

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('parameters')}
                line={1}
                backButton={true}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                navigation={props.navigation}
                rounded={(User._instance.avatar.get() ?? '') !== ''}
                logo={User._instance.avatar.get() ?? ''}
                fallback={
                    <UserIcon
                        color={'white'}
                        size={35}
                    />
                }
            >
                <View
                    style={{
                        marginTop: 15,
                    }}
                >
                    {actionList.map((action, index) => {
                        return (
                            <Pressable
                                key={index}
                                style={[
                                    index !== 0 && {
                                        borderTopWidth: 1,
                                        borderColor: getColor('border'),
                                    },
                                    {
                                        flexDirection: 'row',
                                        marginHorizontal: 20,
                                        padding: 10,
                                        alignItems: 'center',
                                        backgroundColor: getColor('card'),
                                    },
                                    index === 0 && {
                                        borderTopLeftRadius: 15,
                                        borderTopRightRadius: 15,
                                    },
                                    index === actionList.length - 1 && {
                                        borderBottomLeftRadius: 15,
                                        borderBottomRightRadius: 15,
                                    },
                                ]}
                                onPress={(e) => {
                                    e.stopPropagation();
                                    action.onClick(props.navigation);
                                }}
                            >
                                <PastilleView
                                    size={32}
                                    color={getColor('secondary')}
                                    icon={action.icon}
                                />
                                <Text
                                    adjustsFontSizeToFit
                                    style={[
                                        {
                                            marginLeft: 10,
                                            color: 'black',
                                            fontFamily: FONT_MEDIUM,
                                            fontSize: 16,
                                            flex: 1,
                                        },
                                    ]}
                                >
                                    {t(action.label)}
                                </Text>
                            </Pressable>
                        );
                    })}
                </View>
            </Header>
        </View>
    );
};
