import { t } from 'i18next';
import { HTTPUtils } from 'mp-ts-http';
import { get, post } from '../utils/http';
import { useEffect, useState } from 'react';
import {
    Dimensions,
    Platform,
    NativeModules,
    Keyboard,
    Alert,
} from 'react-native';
import React from 'react';
import User from '../object/User';
import { SERVER_URL } from './constants';
import ImageResizer from 'react-native-image-resizer';
//import RNFetchBlob from 'rn-fetch-blob';
//@ts-ignore
import piexif from 'piexifjs';
import { applyResult } from './applyResult';
import JTOObject from './jto/JTOObject';

export const mustLoginButton = (
    navigation: any,
    t: (key: string) => string,
    callback: (...rest: any) => void,
) => {
    if (User._instance.nothing) {
        navigation.navigate('MustLogin');
        /*alert(t('must_login_for_this_action_title'));
        Alert.alert(
            t('must_login_for_this_action_title'),
            t('must_login_for_this_action_description'),
            [
                {
                    text: t('cancel'),
                    onPress: () => {},
                    style: 'cancel',
                },
                {
                    text: t('login'),
                    onPress: () => {
                        navigation.navigate('Login');
                    },
                    style: 'default',
                },
            ],
            { cancelable: false },
        );*/
    } else {
        callback();
    }
};

export const getJTOObjectList = (obj: JTOObject, identifier: string) => {
    const jtoObjectList = [] as JTOObject[];
    const list = obj.getJTOObjectList(true);
    const finalList = list.filter((elem) => {
        return elem.getJTOIdentifier() === identifier;
    });
    return finalList;
};

export const toNumeric = (str: string) => {
    return parseInt(str, 10);
};

export const getDisplayDateUtils = (
    translation: (...args: any) => string,
    dateString: string,
): string => {
    let text = '';

    const date = new Date(dateString);
    const now = Date.now();
    const timeDiff = now - date.getTime();

    if (timeDiff < 5000) {
        text = translation('just_now');
    } else if (timeDiff < 60000) {
        const seconde = Math.ceil(timeDiff / 1000);
        if (seconde === 1) {
            text = translation('one_s');
        } else {
            text = translation('s', { count: seconde });
        }
    } else if (timeDiff < 3600000) {
        const minute = Math.ceil(timeDiff / 60000);
        if (minute === 1) {
            text = translation('one_m');
        } else {
            text = translation('m', { count: minute });
        }
    } else if (timeDiff < 86400000) {
        const heure = Math.ceil(timeDiff / 3600000);
        if (heure === 1) {
            text = translation('one_h');
        } else {
            text = translation('h', { count: heure });
        }
    } else if (timeDiff < 1209600000) {
        const day = Math.ceil(timeDiff / 86400000);
        if (day === 1) {
            text = translation('one_d');
        } else {
            text = translation('d', { count: day });
        }
    } else {
        text = date.toLocaleDateString();
    }
    return text;
};

export const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

export const closeAction = () => {
    post('/chat/close', {
        id_him: User._instance.getCurrentChatId(),
        type: User._instance.getCurrentChatType(),
    })
        .then((res) => {
            if (HTTPUtils.isSuccess(res)) {
                applyResult(res, User._instance, true);
                applyResult(res, User._instanceConnect, true);
                applyResult(res, User._instanceChat, true);
                fetchNewMessage();
            } else {
                // showToast(t('went_wrong'), 3000);
            }
        })
        .catch((err) => {
            // showToast(t('went_wrong'), 3000);
        });
    return true;
};

export async function getBlob(
    action: string,
    params: { [key: string]: any } = {},
) {
    let paramStr = '';

    let i = 0;
    for (const key in params) {
        if (i == 0) {
            paramStr += '?';
        } else {
            paramStr += '&';
        }
        paramStr += key + '=' + params[key];
        i++;
    }

    let res = null;
    try {
        const response = await fetch(SERVER_URL + action + paramStr, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        if (!response.ok) {
            res = {
                status: 'failed',
                error: 'went_wrong',
            };
        } else {
            res = await response.blob();
        }
    } catch (error) {
        res = {
            status: 'failed',
            error: 'went_wrong',
        };
    }
    return res;
}

export const getURL = (elem: JTOObject, type: string) => {
    //if elem is a Event
    let values = {};

    if (type === 'event') {
        values = {
            type,
            id: elem.getJTOIdentifier(),
        };

        //values stringify and encode
    } else if (type === 'sub_event') {
        values = {
            type,
            id: User._instance.getEvent()?.getJTOIdentifier(),
            id_sub: elem.getJTOIdentifier(),
        };
    }

    const data = encodeURIComponent(JSON.stringify(values));
    return SERVER_URL + '/redirect/sharing?param=' + data;
};

export const goBackTo = (
    navigation: any,
    screen: string | string[],
    actu: string,
) => {
    const routes = navigation.getState();
    let count = 0;
    if (typeof screen === 'string') {
        screen = [screen];
    }
    if (
        routes &&
        routes.routes &&
        routes.routes[routes.routes.length - 1].name === actu
    ) {
        count = 1;
        for (let i = routes.routes.length - 2; i >= 0; i--) {
            if (screen.includes(routes.routes[i].name)) {
                break;
            }
            count++;
        }
    }

    if (count > 0) {
        for (let i = 0; i < count; i++) {
            navigation.goBack();
        }
    }
};

export const fetchNewMessage = () => {
    get('/user/new_message', {}).then((res) => {
        if (HTTPUtils.isSuccess(res)) {
            applyResult(res, User._instance);
        }
    });
};

export const openAction = () => {
    post('/chat/open', {
        id_him: User._instance.getCurrentChatId(),
        type: User._instance.getCurrentChatType(),
    })
        .then((res) => {
            if (HTTPUtils.isSuccess(res)) {
                applyResult(res, User._instance, true);
                applyResult(res, User._instanceConnect, true);
                applyResult(res, User._instanceChat, true);
                fetchNewMessage();
            } else {
                // showToast(t('went_wrong'), 3000);
            }
        })
        .catch((err) => {
            // showToast(t('went_wrong'), 3000);
        });
    return true;
};

export const useKeyboardVisible = () => {
    const [isKeyboardVisible, setKeyboardVisible] = useState(false);

    useEffect(() => {
        const keyboardDidShowListener = Keyboard.addListener(
            'keyboardDidShow',
            () => {
                setKeyboardVisible(true); // or some other action
            },
        );
        const keyboardDidHideListener = Keyboard.addListener(
            'keyboardDidHide',
            () => {
                setKeyboardVisible(false); // or some other action
            },
        );

        return () => {
            keyboardDidHideListener.remove();
            keyboardDidShowListener.remove();
        };
    }, []);

    return isKeyboardVisible;
};

export const width = () => {
    let width = Math.min(Dimensions.get('screen').width, 420);
    return width;
};

export const height = () => {
    return Dimensions.get('screen').height;
};

export const getDeviceLang = () => {
    if (Platform.OS === 'ios') {
        // iOS:
        return (
            NativeModules.SettingsManager.settings.AppleLocale ||
            NativeModules.SettingsManager.settings.AppleLanguages[0]
        ); // "fr_FR"
    } else if (Platform.OS === 'android') {
        return NativeModules.I18nManager.localeIdentifier; // "fr_FR"
    } else if (Platform.OS === 'web') {
        return navigator.language;
    } else {
        return 'en';
    }
};

export const formatRemainTime = (time: string, translate: any) => {
    let text = '';

    const date = new Date(time);
    const now = Date.now();
    const timeDiff = now - date.getTime();

    if (timeDiff < 5000) {
        text = translate('just_now');
    } else if (timeDiff < 60000) {
        const seconde = Math.ceil(timeDiff / 1000);
        if (seconde === 1) {
            text = translate('one_second_ago');
        } else {
            text = translate('seconds_ago', { count: seconde });
        }
    } else if (timeDiff < 3600000) {
        const minute = Math.ceil(timeDiff / 60000);
        if (minute === 1) {
            text = translate('one_minute_ago');
        } else {
            text = translate('minutes_ago', { count: minute });
        }
    } else if (timeDiff < 86400000) {
        const heure = Math.ceil(timeDiff / 3600000);
        if (heure === 1) {
            text = translate('one_hour_ago');
        } else {
            text = translate('hours_ago', { count: heure });
        }
    } else if (timeDiff < 1209600000) {
        const day = Math.ceil(timeDiff / 86400000);
        if (day === 1) {
            text = translate('one_day_ago');
        } else {
            text = translate('days_ago', { count: day });
        }
    } else {
        text = date.toLocaleDateString();
    }
    return text;
};

export const formatCompressRemainTime = (time: string, translate: any) => {
    let text = '';

    const date = new Date(time);
    const now = Date.now();
    const timeDiff = now - date.getTime();

    if (timeDiff < 5000) {
        text = translate('just_now');
    } else if (timeDiff < 60000) {
        const seconde = Math.ceil(timeDiff / 1000);
        if (seconde === 1) {
            text = translate('one_s');
        } else {
            text = translate('s', { count: seconde });
        }
    } else if (timeDiff < 3600000) {
        const minute = Math.ceil(timeDiff / 60000);
        if (minute === 1) {
            text = translate('one_m');
        } else {
            text = translate('m', { count: minute });
        }
    } else if (timeDiff < 86400000) {
        const heure = Math.ceil(timeDiff / 3600000);
        if (heure === 1) {
            text = translate('one_h');
        } else {
            text = translate('h', { count: heure });
        }
    } else if (timeDiff < 1209600000) {
        const day = Math.ceil(timeDiff / 86400000);
        if (day === 1) {
            text = translate('one_d');
        } else {
            text = translate('d', { count: day });
        }
    } else {
        text = date.toLocaleDateString();
    }
    return text;
};

export const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
}: any) => {
    const paddingToBottom = 100;
    return (
        layoutMeasurement.height + contentOffset.y >=
        contentSize.height - paddingToBottom
    );
};

export async function postMultipleFile(
    action: string,
    file: (File | string)[] | null,
    params: { [key: string]: any } = {},
) {
    let res = null;
    const formData = new FormData();
    // Add all params to formData
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            formData.append(key, params[key]);
        }
    }
    if (file != null) {
        file.map((fil) => {
            if (fil != null) {
                formData.append('file', fil);
            }
        });
    }

    try {
        const response = await fetch(action, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
            },
            body: formData,
            credentials: 'include',
        });
        if (!response.ok) {
            res = await response.json();
        } else {
            res = await response.json();
        }
    } catch (error) {
        return {
            status: 'failed',
            error: 'something_went_wrong',
        };
    }
    return res;
}

export async function postFile(
    action: string,
    file: any,
    params: { [key: string]: any } = {},
) {
    let res = null;
    const formData = new FormData();
    // Add all params to formData
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            formData.append(key, params[key]);
        }
    }
    if (file != null) {
        formData.append('file', file);
    }

    try {
        const response = await fetch(SERVER_URL + action, {
            method: 'POST',
            headers: {
                Authorization: '',
            },
            body: formData,
            credentials: 'include',
        });
        if (!response.ok) {
            res = {
                status: 'failed',
                error: 'went_wrong',
            };
        } else {
            res = await response.json();
        }
    } catch (error) {
        res = {
            status: 'failed',
            error: 'went_wrong',
        };
    }
    return res;
}

export const hexToRgb = (
    hexStr: string,
    strength = 1.0,
): [number, number, number] => {
    // Remove the "#" character from the hex string
    hexStr = hexStr.replace('#', '');

    // Convert the hex string to RGB values
    const r = parseInt(hexStr.substring(0, 2), 16);
    const g = parseInt(hexStr.substring(2, 4), 16);
    const b = parseInt(hexStr.substring(4, 6), 16);

    // Apply the strength coefficient to each RGB value
    const red = (r / 255.0) * strength;
    const green = (g / 255.0) * strength;
    const blue = (b / 255.0) * strength;

    // Return the RGB values as a tuple
    return [red, green, blue];
};
export async function getOrientationFromUri(assetUri: string): Promise<string> {
    try {
        //const imageData = await RNFetchBlob.fs.readFile(assetUri, 'base64');
        const exifData = piexif.load('data:image/jpeg;base64,' /*+ imageData*/);

        if (
            exifData &&
            exifData['0th'] &&
            exifData['0th'][piexif.ImageIFD.Orientation]
        ) {
            // Apply the correct rotation according to the orientation data
            switch (exifData['0th'][piexif.ImageIFD.Orientation]) {
                case 3:
                    return '180';
                case 6:
                    return '270';
                case 8:
                    return '90';
                default:
                    return '0';
            }
        }
    } catch (error) {
        // console.warn('Error reading EXIF data:', error);
    }
    return '0';
}

export async function resizeAndFixRotation(
    uri: string,
    rotation: string,
): Promise<string> {
    const { uri: resizedUri } = await ImageResizer.createResizedImage(
        uri,
        1000,
        1000,
        'JPEG',
        100,
        parseInt(rotation, 10),
    );
    return resizedUri;
}

export const cantIfNotLoggin = (navigation: any) => {
    if (User._instance.isAuth()) {
        return true;
    } else {
        navigation.navigate('BeforeLogin');
        return false;
    }
};
