import { Image, Text, View } from 'react-native';
import React from 'react';
import Ge from '../svg/Ge';
import { Logo } from '../svg/Logo';
import { STATUS_BAR_HEIGHT } from '../utils/screen';
import { styles } from '../utils/styles';
import { marginBottom } from '../utils/tailwinds';
import { getColor } from '../utils/theme';
import { width } from '../utils/utils';

export interface IHeaderSimplePageProps {
    title: string;
    description: string;
    color?: string;
}

export const HeaderSimplePage = (props: IHeaderSimplePageProps) => {
    const { title, color = getColor('primary'), description } = props;
    return (
        <View
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <View
                style={{
                    marginTop: STATUS_BAR_HEIGHT + width() * 0.05,
                    marginVertical: width() * 0.05,
                    padding: 10,
                    borderRadius: width() * 0.2,
                    backgroundColor: getColor('background'),
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Image
                    source={require('../../assets/logoGrandAria.jpg')}
                    style={{
                        width: width() * 0.5,
                        height: width() * 0.2,
                    }}
                />
            </View>

            <Text
                style={[
                    styles.h3,
                    {
                        textAlign: 'center',
                        width: width() - 40,
                        maxWidth: width() - 40,
                        flexWrap: 'wrap',
                    },
                    marginBottom(5),
                ]}
                allowFontScaling
                adjustsFontSizeToFit
            >
                {title}
            </Text>
            <Text
                style={[
                    styles.text,
                    marginBottom(5),
                    {
                        textAlign: 'center',
                    },
                ]}
            >
                {description}
            </Text>
        </View>
    );
};
