import React, { useEffect, useState } from 'react';
//import LottieView from 'lottie-react-native';
import { View, Text, Pressable } from 'react-native';
import { experienceAnim } from '../anim/experience';
import { HEADER_BONUS_HEIGHT, STATUS_BAR_HEIGHT } from '../utils/screen';
import { FONT_BOLD, getColor } from '../utils/theme';
import { pulseAnim } from '../anim/pulse';
import Lottie from 'lottie-react';
/*import Animated, {
    runOnJS,
    useAnimatedStyle,
    withTiming,
} from 'react-native-reanimated';*/

export let openExperience = (xp: number) => {};

export const ExperienceView = (props: any) => {
    const [xp, setXp] = useState(0);
    const [open, setOpen] = useState(false);
    const [openAlpha, setOpenAlpha] = useState(false);
    const [timer, setTimer] = useState<any>(null);

    openExperience = (newXp: number) => {
        setXp(xp + newXp);
        setOpen(true);
        setOpenAlpha(true);
    };

    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        setTimer(
            setTimeout(() => {
                setOpenAlpha(false);
            }, 5000 + 300),
        );
    }, [open]);

    function hide() {
        setOpen(false);
        setXp(0);
    }

    /*const opacityStyle = useAnimatedStyle(() => {
        return {
            opacity: withTiming(
                openAlpha ? 1 : 0,
                {
                    duration: 300,
                },
                () => {
                    if (!openAlpha) {
                        runOnJS(hide)();
                    }
                },
            ),
        };
    });*/

    return open ? (
        <Pressable
            style={{
                position: 'absolute',
                right: 10,
                top: 30,
            }}
            onPress={hide}
        >
            <View
                style={{
                    position: 'absolute',
                    top: 30,
                    bottom: 15,
                    left: 0,
                    right: 0,
                    backgroundColor: getColor('secondary'),
                }}
            ></View>
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    overflow: 'hidden',
                    height: 60,
                    width: 60,
                    backgroundColor: getColor('secondary'),
                    borderRadius: 200,
                }}
            >
                <Lottie
                    style={{
                        height: 60,
                        width: 60,
                        position: 'absolute',
                    }}
                    autoPlay
                    loop={true}
                    animationData={pulseAnim()}
                />
                <Lottie
                    style={{
                        position: 'absolute',
                        height: 80,
                        width: 80,
                        marginTop: -4,
                    }}
                    autoPlay
                    loop={true}
                    animationData={experienceAnim}
                />
                {/*<LottieView
                    style={{
                        position: 'absolute',
                        height: 80,
                        width: 80,
                        marginTop: -4,
                    }}
                    autoPlay
                    loop={true}
                    source={pulseAnim()}
                />
                <LottieView
                    style={{
                        position: 'absolute',
                        height: 80,
                        width: 80,
                        marginTop: -4,
                    }}
                    autoPlay
                    loop={true}
                    source={experienceAnim}
                />*/}
            </View>
            <View
                style={{
                    height: 30,
                    width: 60,
                    borderColor: getColor('primary'),
                    backgroundColor: getColor('secondary'),
                    borderRadius: 200,
                }}
            >
                <Text
                    style={{
                        fontFamily: FONT_BOLD,
                        color: 'white',
                        fontSize: 20,
                        padding: 4,
                        width: 60,
                        textAlign: 'center',
                    }}
                    numberOfLines={1}
                    adjustsFontSizeToFit
                >
                    +{xp}
                </Text>
            </View>
        </Pressable>
    ) : (
        <></>
    );
};
{
    /*<Animated.View
            style={[
                {
                    position: 'absolute',
                    right: 20,
                    width: 60,
                    top: STATUS_BAR_HEIGHT + 10 + 75,
                },
                opacityStyle,
            ]}
        >*/
}
{
    /*</Animated.View>*/
}
