import { CommonActions } from '@react-navigation/native';
import { getErrorMessage } from 'mp-ts-error';
import { HTTPUtils } from 'mp-ts-http';
import { post } from '../utils/http';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Pressable, Text, View } from 'react-native';
//import { ScrollView } from 'react-native-gesture-handler';
import {
    CheckCircleIcon,
    EyeIcon,
    EyeSlashIcon,
} from 'react-native-heroicons/solid';
import { MpInput } from '../base/MpInput';
import { CartoucheButton } from '../component/CartoucheButton';
import { ContentCartouche } from '../component/ContentCartouche';
import { GeSpinner } from '../component/GeSpinner';
import { Header } from '../component/Header';
import { HeaderSimplePage } from '../component/HeaderSimplePage';
import User from '../object/User';
import Ge from '../svg/Ge';
import Logo from '../svg/Logo';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { styles } from '../utils/styles';
import { marginBottom, marginTop } from '../utils/tailwinds';
import { APP_NAME, getColor } from '../utils/theme';
import { width } from '../utils/utils';
import { IPageProps } from './IPageProps';
import { nextLogin } from './LoginPage';
import { BEFORE_LOGO } from '../utils/config';

export const PasswordPage = (props: IPageProps) => {
    const { t } = useTranslation();
    User.translation = t;

    const scrollRef = useRef<any>(null);

    const [success, setSuccess] = useState(false);

    const [password, setPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState(false);

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    if (success) {
        const onFinish = () => {
            if (!loading) {
                setLoading(true);
                post('/user/login', {
                    identifier: User._email,
                    password: User._password,
                }).then((res) => {
                    setLoading(false);
                    User._password = '';
                    User._email = '';
                    User._password = '';
                    if (HTTPUtils.isSuccess(res)) {
                        setMessage('');
                        const data = HTTPUtils.getResult(res);
                        User._instance?.login(data);

                        nextLogin(props.navigation, setMessage, setLoading, t);
                    } else {
                        props.navigation.dispatch(
                            CommonActions.reset({
                                index: 0,
                                routes: [{ name: 'BeforeLogin' }],
                            }),
                        );
                    }
                });
            }
        };
        return (
            <View style={[styles.page]}>
                <Header
                    title={APP_NAME}
                    beforeLogo={BEFORE_LOGO}
                    fallback={
                        <Logo
                            height={50}
                            width={50}
                        />
                    }
                    fallbackColor={'white'}
                    after={
                        <View
                            style={{
                                height: 10,
                            }}
                        ></View>
                    }
                    full
                    logo={''}
                >
                    <View
                        ref={scrollRef}
                        // @ts-ignore
                        automaticallyAdjustKeyboardInsets={true}
                    >
                        <View
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: width() * 0.15,
                                marginLeft: width() * 0.15,
                                width: width() * 0.7,
                                paddingBottom: NAVIGATION_BAR_HEIGHT,
                            }}
                        >
                            <HeaderSimplePage
                                title={t('password')}
                                description={t('password_init')}
                            />

                            <CheckCircleIcon
                                style={{
                                    marginVertical: width() * 0.05,
                                }}
                                color={getColor('success')}
                                size={width() * 0.2}
                            />

                            {loading ? (
                                <View
                                    style={[
                                        styles.boxCenter,
                                        marginTop(15),
                                        marginBottom(10),
                                    ]}
                                >
                                    <GeSpinner color={getColor('success')} />
                                </View>
                            ) : (
                                <View>
                                    <CartoucheButton
                                        title={t('finish')}
                                        color={getColor('success')}
                                        onPress={() => {
                                            onFinish();
                                        }}
                                        height={80}
                                    />
                                </View>
                            )}
                        </View>
                    </View>
                </Header>
            </View>
        );
    }

    const onResetPassword = () => {
        setLoading(true);
        setMessage('');
        setSuccess(false);
        post('/user/recover', {
            identifier: User._email,
            token: User._code,
            password,
        })
            .then((res) => {
                setLoading(false);
                if (HTTPUtils.isSuccess(res)) {
                    setSuccess(true);
                    User._password = password;
                    setPassword('');
                    setMessage('');
                    setLoading(false);
                } else {
                    setMessage(t(HTTPUtils.getError(res)) ?? '');
                }
            })
            .catch((err) => {
                setLoading(false);

                setMessage(t('went_wrong_no_internet') ?? '');
            });
    };

    return (
        <View style={[styles.page]}>
            <Header
                title={APP_NAME}
                beforeLogo={BEFORE_LOGO}
                fallback={
                    <Logo
                        height={40}
                        width={40}
                    />
                }
                fallbackColor={'white'}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                full
                logo={''}
            >
                <View
                    ref={scrollRef}
                    // @ts-ignore
                    automaticallyAdjustKeyboardInsets={true}
                >
                    <View
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: width() * 0.15,
                            marginLeft: width() * 0.15,
                            width: width() * 0.7,
                            paddingBottom: NAVIGATION_BAR_HEIGHT,
                        }}
                    >
                        <HeaderSimplePage
                            title={t('reset_password')}
                            description={
                                t('password_reset_description') +
                                ' ' +
                                t('password_requirement')
                            }
                        />

                        {loading ? (
                            <View style={[styles.boxCenter, marginTop(75)]}>
                                <GeSpinner />
                            </View>
                        ) : (
                            <View
                                style={[
                                    styles.box,
                                    {
                                        width: width(),
                                    },
                                ]}
                            >
                                <View
                                    style={{
                                        paddingHorizontal: width() * 0.1,
                                        paddingVertical: width() * 0.1,
                                    }}
                                >
                                    <MpInput
                                        rightOnPress={() => {
                                            setShowPassword(!showPassword);
                                        }}
                                        rightIcon={
                                            showPassword ? (
                                                <EyeIcon
                                                    color={getColor('hint')}
                                                />
                                            ) : (
                                                <EyeSlashIcon
                                                    color={getColor('hint')}
                                                />
                                            )
                                        }
                                        secureTextEntry={!showPassword}
                                        autoCapitalize='none'
                                        keyboardType={'default'}
                                        textContentType='password'
                                        placeholder={t('password') as string}
                                        style={styles.input}
                                        value={password}
                                        defaultValue={password}
                                        onChangeText={(text) => {
                                            setPassword(text);
                                        }}
                                        selectionColor={getColor('primary')}
                                    />
                                    {message !== '' && (
                                        <Text
                                            style={[
                                                styles.textError,
                                                marginTop(25),
                                            ]}
                                        >
                                            {message}
                                        </Text>
                                    )}
                                </View>

                                <CartoucheButton
                                    onPress={() => {
                                        onResetPassword();
                                    }}
                                    title={t('change_password')}
                                    height={80}
                                />

                                <Pressable
                                    style={{
                                        marginTop: 25,
                                    }}
                                    onPress={() => {
                                        props.navigation.goBack();
                                        setPassword('');
                                        setMessage('');
                                        setLoading(false);
                                    }}
                                >
                                    <Text
                                        style={[
                                            styles.text,
                                            {
                                                textAlign: 'center',
                                                color: getColor('primary'),
                                            },
                                        ]}
                                    >
                                        {t('back')}
                                    </Text>
                                </Pressable>
                            </View>
                        )}
                    </View>
                </View>
            </Header>
        </View>
    );
};
