import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

function Ge(props: SvgProps) {
    return (
        <Svg
            viewBox='0 0 142.96 142.45'
            {...props}
        >
            <Path d='M142.23 62.83c4.85 42.04-14.18 73.15-62.62 78.74C31.35 147.13 5.57 121.18.72 79.14-4.12 37.11 15.29 6.29 63.38.75c48.09-5.54 74.01 20.04 78.85 62.08zm-13.75 1.58c-4.03-34.96-22.97-55.84-63.66-51.15-40.51 4.67-54.38 29.33-50.35 64.29 4.01 34.78 22.65 56.24 63.7 51.51 41.23-4.75 54.32-29.88 50.31-64.65z' />
            <Path d='M70.59 65.25l2.32 15.1 18.94-2.91.2 1.28c.98 6.4-1.82 12.86-15.78 15-14.72 2.26-21.78-6.22-23.91-20.04-2.3-14.98 1.73-25.42 15.42-27.52 8.29-1.27 13.75 1.41 17.47 7.19l17.39-6.15c-6.24-13.49-18.4-20.47-36.88-17.63-25.34 3.89-35.73 22.39-31.96 46.96 3.5 22.78 17.97 36.8 38.19 33.7 14.46-2.22 20.26-11.37 21.1-22.11l.38-.06 2.63 17.15 17.41-2.67-6.58-42.88-36.35 5.58z' />
        </Svg>
    );
}

export default Ge;
