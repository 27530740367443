import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import React from 'react';
import { CartoucheButton } from '../component/CartoucheButton';
import { Header } from '../component/Header';
import { HeaderSimplePage } from '../component/HeaderSimplePage';
import Logo from '../svg/Logo';
import { BEFORE_LOGO } from '../utils/config';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { styles } from '../utils/styles';
import { APP_NAME, getColor } from '../utils/theme';
import { width } from '../utils/utils';

export const ErrorPage = (props: any) => {
    const { title, description } = props.route.params;

    const { t } = useTranslation();

    return (
        <View style={[styles.page]}>
            <Header
                title={APP_NAME}
                beforeLogo={BEFORE_LOGO}
                fallback={
                    <Logo
                        height={40}
                        width={40}
                    />
                }
                backButton={true}
                navigation={props.navigation}
                line={1}
                fallbackColor={'white'}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                full
                logo={''}
            >
                <View // @ts-ignore
                    automaticallyAdjustKeyboardInsets={true}
                >
                    <View
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flex: 1,
                            marginRight: width() * 0.15,
                            marginLeft: width() * 0.15,
                            width: width() * 0.7,
                        }}
                    >
                        <HeaderSimplePage
                            color={getColor('ge')}
                            title={t(title)}
                            description={t(description)}
                        />

                        <View
                            style={{
                                marginTop: 50,
                            }}
                        >
                            <CartoucheButton
                                onPress={() => {
                                    props.navigation?.goBack?.();
                                }}
                                color={getColor('ge')}
                                title={t('back')}
                                height={80}
                            />
                        </View>
                    </View>
                </View>
            </Header>
        </View>
    );
};
