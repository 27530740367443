import { Linking, Pressable, ScrollView, Text, View } from 'react-native';
import User from '../object/User';

import { styles } from '../utils/styles';
import React, { useEffect, useState } from 'react';
import { HTTPUtils } from 'mp-ts-http';
import { getErrorMessage } from 'mp-ts-error';
import Event from '../object/Event';
import { Image } from 'react-native';
import { FONT_BOLD, FONT_MEDIUM, FONT_REGULAR, getColor } from '../utils/theme';
import { EventTopPresentation } from '../component/EventTopPresentation';
import {
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import {
    CalendarDaysIcon,
    EnvelopeIcon,
    PhoneIcon,
    UserIcon,
} from 'react-native-heroicons/solid';
import { TabNavigation } from '../component/TabNavigation';
import { Header } from '../component/Header';
import { t } from 'i18next';
import { ContactView } from '../component/ContactView';
import { GeSpinner } from '../component/GeSpinner';
import { ToastManager } from '../component/toast/ToastManager';
import { Toast } from '../component/toast/Toast';
import Contact from '../object/Contact';
import { PastilleView } from '../component/PastilleView';
import { height, width } from '../utils/utils';
import Bonheur from '../svg/Bonheur';
import { getRestore } from '../utils/http';
import useJTOState from '../utils/jto/useJTOState';
import { applyResult } from '../utils/applyResult';

export const ContactPage = (props: any) => {
    const [loading, setLoading] = useState(true);

    const event = useJTOState(User._instance.getEvent()!);

    const loadContact = () => {
        getRestore(
            '/contact/list',
            {
                id: User._instance.getEvent()?.getJTOIdentifier() + '',
            },
            (res) => {
                setLoading(false);
                if (HTTPUtils.isSuccess(res)) {
                    const e = HTTPUtils.getResult(res);
                    applyResult(e, User._instance.getEvent()!, true);
                    User._instance.getEvent()?.notifyViewNow();
                } else {
                    ToastManager.instance.addToast(
                        new Toast(t(HTTPUtils.getError(res)), 5000, 'error'),
                    );
                }
            },
            (err) => {
                setLoading(false);
                ToastManager.instance.addToast(
                    new Toast(t('went_wrong_no_internet'), 5000, 'error'),
                );
            },
            () => {
                event.contactList.setList([]);
            },
        );
    };

    useEffect(() => {
        loadContact();
    }, []);

    const contactEventList =
        User._instance
            .getEvent()
            ?.contactList.getList()
            .filter((contact) => {
                return contact.visible.get() !== false;
            })
            .filter((contact) => {
                return contact.category.get() === 'client';
            })
            .sort((a: Contact, b: Contact) => {
                // Sort by important boolean
                if (a.important.get() === b.important.get()) {
                    return 0;
                }
                if (a.important.get()) {
                    return -1;
                } else {
                    return 1;
                }
            })
            .sort((a: Contact, b: Contact) => {
                const lastname1 = a.lastname.get() ?? '';
                const lastname2 = b.lastname.get() ?? '';

                const firstname1 = a.firstname.get() ?? '';
                const firstname2 = b.firstname.get() ?? '';

                if (lastname1.localeCompare(lastname2) === 0) {
                    return firstname1.localeCompare(firstname2);
                }
                return lastname1.localeCompare(lastname2);
            }) ?? [];

    const contactOrganizerList =
        User._instance
            .getEvent()
            ?.contactList.getList()
            .filter((contact) => {
                return contact.visible.get() !== false;
            })
            .filter((contact) => {
                return contact.category.get() === 'organizer';
            })
            .sort((a: Contact, b: Contact) => {
                // Sort by important boolean
                if (a.important.get() === b.important.get()) {
                    return 0;
                }
                if (a.important.get()) {
                    return -1;
                } else {
                    return 1;
                }
            })
            .sort((a: Contact, b: Contact) => {
                const lastname1 = a.lastname.get() ?? '';
                const lastname2 = b.lastname.get() ?? '';

                const firstname1 = a.firstname.get() ?? '';
                const firstname2 = b.firstname.get() ?? '';

                if (lastname1.localeCompare(lastname2) === 0) {
                    return firstname1.localeCompare(firstname2);
                }
                return lastname1.localeCompare(lastname2);
            }) ?? [];

    const contactExposantList =
        User._instance
            .getEvent()
            ?.contactList.getList()
            .filter((contact) => {
                return contact.visible.get() !== false;
            })
            .filter((contact) => {
                return contact.category.get() === 'exposant';
            })
            .sort((a: Contact, b: Contact) => {
                // Sort by important boolean
                if (a.important.get() === b.important.get()) {
                    return 0;
                }
                if (a.important.get()) {
                    return -1;
                } else {
                    return 1;
                }
            })
            .sort((a: Contact, b: Contact) => {
                const lastname1 = a.lastname.get() ?? '';
                const lastname2 = b.lastname.get() ?? '';

                const firstname1 = a.firstname.get() ?? '';
                const firstname2 = b.firstname.get() ?? '';

                if (lastname1.localeCompare(lastname2) === 0) {
                    return firstname1.localeCompare(firstname2);
                }
                return lastname1.localeCompare(lastname2);
            }) ?? [];

    return (
        <View
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: getColor('background'),
            }}
        >
            <Header
                backButton={true}
                title={t('contacts')}
                line={1}
                navigation={props.navigation}
                logo={event.logo.get() ?? ''}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
            >
                {loading ? (
                    <View
                        style={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            paddingBottom: NAVIGATION_BAR_HEIGHT + TAB_HEIGHT,
                            justifyContent: 'center',
                        }}
                    >
                        <GeSpinner color={getColor('primary')} />
                    </View>
                ) : User._instance.getEvent()?.contactList.getList().length ===
                  0 ? (
                    <ScrollView
                        style={{
                            flex: 1,
                            zIndex: 500,
                        }}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                    >
                        <View
                            style={{
                                height: 30,
                            }}
                        ></View>

                        <View
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingHorizontal: 20,
                                height:
                                    height() -
                                    TAB_HEIGHT -
                                    STATUS_BAR_HEIGHT -
                                    NAVIGATION_BAR_HEIGHT -
                                    150,
                                backgroundColor: getColor('background'),
                            }}
                        >
                            <Text
                                style={{
                                    color: getColor('text'),
                                    fontSize: 16,
                                    fontFamily: FONT_REGULAR,
                                    textAlign: 'center',
                                }}
                            >
                                {t('no_contact')}
                            </Text>
                        </View>
                    </ScrollView>
                ) : (
                    <ScrollView
                        style={{
                            flex: 1,
                            zIndex: 500,
                        }}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                    >
                        <View
                            style={{
                                height: 30,
                            }}
                        ></View>

                        {contactEventList.length > 0 && (
                            <View
                                style={{
                                    marginHorizontal: 20,
                                    marginTop: 15,
                                    marginBottom: 10,
                                    shadowColor: getColor('hint'),
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.2,
                                    shadowRadius: 3.84,
                                    elevation: 5,
                                }}
                            >
                                <View
                                    style={{
                                        borderRadius: 27,
                                        overflow: 'hidden',
                                        backgroundColor: getColor('primary'),
                                        shadowColor: getColor('hint'),
                                    }}
                                >
                                    <View
                                        style={{
                                            padding: 15,
                                            paddingTop: 10,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: 'white',
                                                fontSize: 20,
                                                fontFamily: FONT_BOLD,
                                            }}
                                        >
                                            {t('client_contact_title')}
                                        </Text>
                                        <Text
                                            style={[
                                                {
                                                    fontFamily: FONT_MEDIUM,
                                                    color: 'white',
                                                    fontSize: 17,
                                                },
                                            ]}
                                        >
                                            {contactEventList.length +
                                                ' ' +
                                                (contactEventList.length > 1
                                                    ? t(
                                                          'contacts',
                                                      ).toLowerCase()
                                                    : t(
                                                          'contact',
                                                      ).toLowerCase())}
                                        </Text>
                                        <Text
                                            style={[
                                                {
                                                    fontFamily: FONT_REGULAR,
                                                    color: 'white',
                                                    fontSize: 15,
                                                    marginBottom: -15,
                                                },
                                            ]}
                                        >
                                            {t('client_contact_explain')}
                                        </Text>
                                    </View>
                                    <View>
                                        {contactEventList.map(
                                            (contact, index) => {
                                                return (
                                                    <ContactView
                                                        index={index}
                                                        key={contact.getJTOIdentifier()}
                                                        contact={contact}
                                                    />
                                                );
                                            },
                                        )}
                                    </View>
                                </View>
                            </View>
                        )}
                        {contactOrganizerList.length > 0 && (
                            <View
                                style={{
                                    marginHorizontal: 20,
                                    marginTop: 15,
                                    marginBottom: 10,
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.2,
                                    shadowRadius: 3.84,
                                    elevation: 5,
                                }}
                            >
                                <View
                                    style={{
                                        borderRadius: 27,
                                        overflow: 'hidden',
                                        backgroundColor: getColor('secondary'),
                                        shadowColor: getColor('hint'),
                                    }}
                                >
                                    <View
                                        style={{
                                            padding: 15,
                                            paddingTop: 10,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: 'white',
                                                fontSize: 20,
                                                fontFamily: FONT_BOLD,
                                            }}
                                        >
                                            {t('organizer_contact_title')}
                                        </Text>
                                        <Text
                                            style={[
                                                {
                                                    fontFamily: FONT_MEDIUM,
                                                    color: 'white',
                                                    fontSize: 17,
                                                },
                                            ]}
                                        >
                                            {contactOrganizerList.length +
                                                ' ' +
                                                (contactOrganizerList.length > 1
                                                    ? t(
                                                          'contacts',
                                                      ).toLowerCase()
                                                    : t(
                                                          'contact',
                                                      ).toLowerCase())}
                                        </Text>
                                        <Text
                                            style={[
                                                {
                                                    fontFamily: FONT_REGULAR,
                                                    color: 'white',
                                                    fontSize: 15,
                                                    marginBottom: -15,
                                                },
                                            ]}
                                        >
                                            {t('organizer_contact_explain')}
                                        </Text>
                                    </View>
                                    <View>
                                        {contactOrganizerList.map(
                                            (contact, index) => {
                                                return (
                                                    <ContactView
                                                        index={index}
                                                        key={contact.getJTOIdentifier()}
                                                        contact={contact}
                                                    />
                                                );
                                            },
                                        )}
                                    </View>
                                </View>
                            </View>
                        )}
                        {contactExposantList.length > 0 && (
                            <View
                                style={{
                                    marginHorizontal: 20,
                                    marginTop: 15,
                                    marginBottom: 10,
                                    shadowColor: getColor('hint'),
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.2,
                                    shadowRadius: 3.84,
                                    elevation: 5,
                                }}
                            >
                                <View
                                    style={{
                                        borderRadius: 27,
                                        overflow: 'hidden',
                                        backgroundColor: getColor('success'),
                                        shadowColor: getColor('hint'),
                                    }}
                                >
                                    <View
                                        style={{
                                            padding: 15,
                                            paddingTop: 10,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: 'white',
                                                fontSize: 20,
                                                fontFamily: FONT_BOLD,
                                            }}
                                        >
                                            {t('exposant_contact_title')}
                                        </Text>
                                        <Text
                                            style={[
                                                {
                                                    fontFamily: FONT_MEDIUM,
                                                    color: 'white',
                                                    fontSize: 17,
                                                },
                                            ]}
                                        >
                                            {contactExposantList.length +
                                                ' ' +
                                                (contactExposantList.length > 1
                                                    ? t(
                                                          'contacts',
                                                      ).toLowerCase()
                                                    : t(
                                                          'contact',
                                                      ).toLowerCase())}
                                        </Text>
                                        <Text
                                            style={[
                                                {
                                                    fontFamily: FONT_REGULAR,
                                                    color: 'white',
                                                    fontSize: 15,
                                                    marginBottom: -15,
                                                },
                                            ]}
                                        >
                                            {t('exposant_contact_explain')}
                                        </Text>
                                    </View>
                                    <View>
                                        {contactExposantList.map(
                                            (contact, index) => {
                                                return (
                                                    <ContactView
                                                        index={index}
                                                        key={contact.getJTOIdentifier()}
                                                        contact={contact}
                                                    />
                                                );
                                            },
                                        )}
                                    </View>
                                </View>
                            </View>
                        )}
                        <View
                            style={{
                                height: STATUS_BAR_HEIGHT,
                            }}
                        />
                    </ScrollView>
                )}
            </Header>
        </View>
    );
};
