import {
    InteractionManager,
    Pressable,
    RefreshControl,
    ScrollView,
    Text,
    View,
} from 'react-native';
import React from 'react';
import { PostView } from '../component/PostView';
import { styles } from '../utils/styles';
import { IPageProps } from './IPageProps';
import {
    HEADER_BONUS_HEIGHT,
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import { useCallback, useEffect, useState } from 'react';
import { FONT_BOLD, getColor } from '../utils/theme';
import User from '../object/User';
import { marginBottom, marginTop } from '../utils/tailwinds';
import { height, isCloseToBottom, width } from '../utils/utils';
import { GeSpinner } from '../component/GeSpinner';
import { PlusIcon } from 'react-native-heroicons/solid';
import { Header } from '../component/Header';
import { t } from 'i18next';
import { PastilleView } from '../component/PastilleView';
import { POST_CREATE } from '../object/PermissionEvent';
import useJTOState from '../utils/jto/useJTOState';
import { mustLoginButton } from '../utils/utils';

export const PostPage = (props: IPageProps) => {
    const [refreshing, setRefreshing] = useState(false);

    const postList = useJTOState(User._instance.getEvent()!.postList);
    const event = useJTOState(User._instance.getEvent()!);

    const onRefresh = useCallback(() => {
        setRefreshing(false);
        postList.reset();
        postList.refetch({
            id: User._instance.getEvent()!.getJTOIdentifier(),
        });
    }, []);

    /* useEffect(() => {
        setTimeout(() => {
            try {
                User._instance.getEvent()!.postList.fetch({
                    id: User._instance.getEvent()!.getJTOIdentifier(),
                });
            } catch (e) {
            }
        });
        return () => {
            try {
                User._instance.getEvent()!.postList.reset();
            } catch (e) {
            }
        };
    }, []); */

    useEffect(() => {
        InteractionManager.runAfterInteractions(() => {
            try {
                User._instance.getEvent()!.postList.fetch({
                    id: User._instance.getEvent()!.getJTOIdentifier(),
                });
            } catch (e) {}
        });
        return () => {
            InteractionManager.runAfterInteractions(() => {
                try {
                    User._instance.getEvent()!.postList.reset();
                } catch (e) {}
            });
        };
    }, []);

    return (
        <View
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: width(),
                height: '100%',
                margin: 'auto',
                flex: 1,
            }}
        >
            <Header
                title={t('timeline')}
                line={1}
                navigation={props.navigation}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                logo={event.logo.get() ?? ''}
            >
                <ScrollView
                    // @ts-ignore
                    onScroll={({ nativeEvent }) => {
                        if (isCloseToBottom(nativeEvent)) {
                            postList.more({
                                id: User._instance
                                    .getEvent()!
                                    .getJTOIdentifier(),
                            });
                        }
                    }}
                    onMomentumScrollEnd={({ nativeEvent }) => {
                        if (isCloseToBottom(nativeEvent)) {
                            postList.more({
                                id: User._instance
                                    .getEvent()!
                                    .getJTOIdentifier(),
                            });
                        }
                    }}
                    scrollEventThrottle={1}
                    style={{
                        width: width(),
                        marginTop: -20,
                        zIndex: 500,
                    }}
                    refreshControl={
                        <RefreshControl
                            progressViewOffset={HEADER_BONUS_HEIGHT}
                            colors={[getColor('primary')]}
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                        />
                    }
                >
                    <View
                        style={{
                            height: 60,
                        }}
                    ></View>
                    {postList.map((post, index) => {
                        return (
                            <PostView
                                key={post.getJTOIdentifier()}
                                post={post}
                                navigation={props.navigation}
                                preventOpen={false}
                            />
                        );
                    })}
                    {(postList.isLoading() || !postList.isComplite()) && (
                        <View
                            style={[
                                styles.boxCenter,
                                marginBottom(50),
                                postList.size() === 0
                                    ? marginTop(200)
                                    : marginTop(50),
                            ]}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    )}
                    {postList.size() === 0 &&
                        !postList.isLoading() &&
                        postList.isComplite() && (
                            <View
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height:
                                        height() -
                                        STATUS_BAR_HEIGHT -
                                        NAVIGATION_BAR_HEIGHT -
                                        TAB_HEIGHT -
                                        200,
                                    flex: 1,
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: 25,
                                        fontFamily: FONT_BOLD,
                                        color: getColor('text'),
                                        textAlign: 'center',
                                    }}
                                >
                                    {t('no_post')}
                                </Text>
                                {((User._instance
                                    .getEvent()
                                    ?.hasPermission(POST_CREATE) ??
                                    false) ||
                                    User._instance.nothing) && (
                                    <Pressable
                                        style={[
                                            {
                                                backgroundColor:
                                                    getColor('secondary'),
                                                paddingVertical: 10,
                                                paddingHorizontal: 14,
                                                borderRadius: 10,
                                            },
                                            { marginTop: 30 },
                                        ]}
                                        onPress={() => {
                                            mustLoginButton(
                                                props.navigation,
                                                t,
                                                () => {
                                                    props.navigation.navigate(
                                                        'PostModal',
                                                        {
                                                            event,
                                                        },
                                                    );
                                                },
                                            );
                                        }}
                                    >
                                        <Text style={styles.buttonText}>
                                            {t('new_post')}
                                        </Text>
                                    </Pressable>
                                )}
                            </View>
                        )}
                </ScrollView>
            </Header>

            {((User._instance.getEvent()?.hasPermission(POST_CREATE) ||
                User._instance.nothing) ??
                false) && (
                <Pressable
                    onPress={() => {
                        mustLoginButton(props.navigation, t, () => {
                            props.navigation.navigate('PostModal', {
                                event,
                            });
                        });
                    }}
                    style={{
                        position: 'absolute',
                        padding: 10,
                        bottom: 0,
                        right: 10,
                        borderRadius: 1000,
                    }}
                >
                    <PastilleView
                        size={65}
                        color={getColor('primary')}
                        icon={
                            <PlusIcon
                                size={50}
                                color={'white'}
                            />
                        }
                    />
                </Pressable>
            )}
        </View>
    );
};
