import { HTTPUtils } from 'mp-ts-http';
import { get, post } from '../utils/http';
import { View, Text, Pressable, Linking } from 'react-native';
import React from 'react';
import Event from '../object/Event';
import { FONT_BOLD, FONT_MEDIUM, FONT_REGULAR, getColor } from '../utils/theme';
import { IModalProps } from './IModalProps';
import { useEffect, useState } from 'react';
import { NAVIGATION_BAR_HEIGHT, STATUS_BAR_HEIGHT } from '../utils/screen';
//import { ScrollView } from 'react-native-gesture-handler';
import {
    EventTopPresentation,
    getChip,
    getIcon,
} from '../component/EventTopPresentation';
import { getURL, mustLoginButton } from '../utils/utils';
import { height, width } from '../utils/utils';
import { useTranslation } from 'react-i18next';
import { ToastManager } from '../component/toast/ToastManager';
import { Toast } from '../component/toast/Toast';
import { ScheduleEvent } from '../component/ScheduleEvent';
import {
    ArrowDownOnSquareIcon,
    ArrowLeftOnRectangleIcon,
    BanknotesIcon,
    CalendarDaysIcon,
    ChevronLeftIcon,
    ClockIcon,
    DocumentIcon,
    EllipsisVerticalIcon,
    HandThumbUpIcon,
    MapPinIcon,
    NewspaperIcon,
    PaperClipIcon,
    ShareIcon,
    UserGroupIcon,
    XMarkIcon,
} from 'react-native-heroicons/solid';
import { Header } from '../component/Header';
import User from '../object/User';
import { PastilleView } from '../component/PastilleView';
import { CartoucheButton } from '../component/CartoucheButton';
import EventType from '../object/EventType';
import SocketInstance from '../utils/SocketInstance';
import { EventTabs } from '../component/event/EventTabs';
import { BACKOFFICE_URL, SERVER_URL } from '../utils/constants';
import { FileElementView } from '../component/FileElementView';
import { GeSpinner } from '../component/GeSpinner';
import { PostEventPage } from '../component/event/PostEventPage';
import { UnderEventPage } from '../component/event/UnderEventPage';
import { ArrowRightOnRectangleIcon } from 'react-native-heroicons/solid';
import { SondageView } from '../component/SondageView';
import { InfoEventView } from '../component/InfoEventView';
import { TinyCartoucheButton } from '../component/TinyCartoucheButton';
import { getRestore } from '../utils/http';
import { CAN_VOTE } from '../object/PermissionEvent';
import { PresentQuiz } from '../component/PresentQuiz';
import JTOInstance from '../utils/jto/JTOInstance';
import { applyResult } from '../utils/applyResult';
import useJTOState from '../utils/jto/useJTOState';
import { CustomTextWithLinks } from '../component/CustomTextWithLinks';
import { CloseAllButton } from '../component/CloseAllButton';
import Payment from '../object/Payment';
import Clipboard from '@react-native-clipboard/clipboard';

export const registerAdd = (
    event: Event,
    t: (name: string) => string,
    setLoading: (load: boolean) => void,
) => {
    if (event.form.get()) {
        Linking.openURL(
            BACKOFFICE_URL +
                '/inscription?id=' +
                event.getJTOIdentifier() +
                '&id_user=' +
                User._instance.getJTOIdentifier(),
        );
    } else {
        setLoading(true);
        post('/event/register/addperso', {
            event_id: event.getJTOIdentifier(),
        })
            .then((res) => {
                setLoading(false);
                if (HTTPUtils.isSuccess(res)) {
                    const e = HTTPUtils.getResult(res);
                    JTOInstance.applyResult(e, true);
                    ToastManager.instance.addToast(
                        new Toast(t('toast.register.success'), 5000, 'success'),
                    );
                } else {
                    ToastManager.instance.addToast(
                        new Toast(t(HTTPUtils.getError(res)), 5000, 'error'),
                    );
                }
            })
            .catch((err) => {
                setLoading(false);
                ToastManager.instance.addToast(
                    new Toast(t('went_wrong_no_internet'), 5000, 'error'),
                );
            });
    }
};

export const SubEventModal = (props: IModalProps) => {
    const { t } = useTranslation();

    const event = useJTOState(new Event());

    const [loading, setLoading] = useState(true);
    const [loadingFile, setLoadingFile] = useState(true);
    const [loadingRegister, setLoadingRegister] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoadingFile(true);
            loadEvent();
        });

        return () => {
            setTimeout(() => {
                event.contactList.reset();
                event.postList.reset();
                event.subEventList.reset();
                event.trombinoscopeList.reset();
            });
        };
    }, []);

    const loadEvent = () => {
        setLoading(true);
        getRestore(
            '/event/get',
            {
                id: (
                    (props as any).route.params.event as Event
                ).getJTOIdentifier(),
            },
            (res: any) => {
                setLoading(false);
                setLoadingFile(false);
                if (HTTPUtils.isSuccess(res)) {
                    const e = HTTPUtils.getResult(res);
                    console.log(JSON.stringify(e, null, 4));
                    applyResult(e, event, true);
                } else {
                    // TODO: error
                }
            },
            (err: any) => {
                setLoading(false);
                setLoadingFile(false);
                // TODO: error
            },
            (res: any) => {
                event.eventType.set(null);
                event.fileList.setList([]);
            },
        );
    };

    const nextQuiz = () => {
        props.navigation.push('QuizModal', {
            event,
            navigation: props.navigation,
        });
        event.resetQuiz();
    };

    const onShare = () => {
        const url = getURL(event, 'sub_event');

        Clipboard.setString(url);

        ToastManager.instance.addToast(
            new Toast(t('toast.share.success'), 5000, 'success'),
        );
    };

    const joinrealTimeQuiz = () => {
        SocketInstance.get().socket?.emit('join.quiz.mobile', {
            id_event: (
                (props as any).route.params.event as Event
            ).getJTOIdentifier(),
        });
        props.navigation.push('RealTimeQuizModal', {
            event,
            navigation: props.navigation,
        });
        event.questionCurrentRealTime = 0;
    };

    const joinDirectQuiz = () => {
        props.navigation.push('DirectQuizModal', {
            event,
            navigation: props.navigation,
        });
    };
    return (
        <View
            style={{
                width: width(),
                paddingBottom: 0,
                margin: 'auto',
                flex: 1,
                backgroundColor: getColor('background'),
            }}
        >
            <Header
                modal
                full
                onPresslogo={() => {
                    props.navigation.popToTop();
                    props.navigation.navigate('home');
                }}
                line={(event.name.get() ?? '').length <= 25 ? 1.01 : 3}
                title={event.name.get() ?? ''}
                price={event.paidEvent ? event.price : undefined}
                after={<View></View>}
                before={
                    <View
                        style={{
                            paddingBottom: 10,
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingHorizontal: 10,
                        }}
                    >
                        <Pressable onPress={() => props.navigation.goBack()}>
                            <PastilleView
                                size={35}
                                icon={<ChevronLeftIcon color={'white'} />}
                                color={getColor('secondary')}
                            />
                        </Pressable>
                        {event.eventType.get() ? (
                            <View
                                style={{
                                    flex: 1,
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    marginHorizontal: 20,
                                    marginBottom: 5,
                                }}
                            >
                                <CartoucheButton
                                    color={getColor('secondary')}
                                    height={45}
                                    fontSize={20}
                                    iconSize={20}
                                    icon={
                                        <View
                                            style={{
                                                marginEnd: 5,
                                            }}
                                        >
                                            {getIcon(event.eventType.get()!)}
                                        </View>
                                    }
                                    title={
                                        event.eventTypeAlias.get() ??
                                        event.eventType.get()!.name.get() ??
                                        ''
                                    }
                                />
                            </View>
                        ) : (
                            <View></View>
                        )}
                        {(User._instance.getEvent()?.hasPermission(CAN_VOTE) ??
                            false) &&
                            event.registrable.get() === true && (
                                <Pressable
                                    onPress={() => {
                                        props.navigation.navigate(
                                            'MenuEventModal',
                                            {
                                                event,
                                            },
                                        );
                                    }}
                                >
                                    <PastilleView
                                        size={35}
                                        icon={
                                            <EllipsisVerticalIcon
                                                color={'white'}
                                            />
                                        }
                                        color={getColor('secondary')}
                                    />
                                </Pressable>
                            )}
                    </View>
                }
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        flex: 1,
                        marginTop: -50,
                        height: '100%',
                    }}
                >
                    <View
                        style={{
                            paddingTop: 50,
                            flex: 1,
                            height: '100%',
                            position: 'relative',
                            paddingBottom: 50,
                        }}
                    >
                        <View
                            style={{
                                width: '100%',

                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingBottom: 20,
                            }}
                        >
                            <View
                                // @ts-ignore
                                //showsHorizontalScrollIndicator={false}
                                //horizontal={true}
                                style={{
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                    paddingVertical: 20,
                                    backgroundColor: getColor('primary'),
                                }}
                            >
                                {event.registrable.get() === true &&
                                    (!event.form.get() ? (
                                        <Pressable
                                            style={{
                                                justifyContent: 'center',
                                                marginHorizontal: 10,
                                                alignItems: 'center',
                                                paddingBottom: 5,
                                            }}
                                            onPress={() => {
                                                mustLoginButton(
                                                    props.navigation,
                                                    t,
                                                    () => {
                                                        if (!loadingRegister) {
                                                            if (
                                                                event.hasRegister
                                                                    .get()
                                                                    ?.want.get() ===
                                                                true
                                                            ) {
                                                                props.navigation.navigate(
                                                                    'RegisterDeleteModal',
                                                                    {
                                                                        event,
                                                                    },
                                                                );
                                                            } else {
                                                                registerAdd(
                                                                    event,
                                                                    t,
                                                                    setLoadingRegister,
                                                                );
                                                            }
                                                        }
                                                    },
                                                );
                                            }}
                                        >
                                            <PastilleView
                                                color={getColor('secondary')}
                                                size={55}
                                                icon={
                                                    !loadingRegister ? (
                                                        event.hasRegister
                                                            .get()
                                                            ?.want.get() !==
                                                        true ? (
                                                            <ArrowLeftOnRectangleIcon
                                                                height={35}
                                                                width={35}
                                                                fill={'white'}
                                                            />
                                                        ) : (
                                                            <ArrowRightOnRectangleIcon
                                                                height={35}
                                                                width={35}
                                                                fill={'white'}
                                                            />
                                                        )
                                                    ) : (
                                                        <GeSpinner
                                                            size={30}
                                                            color='white'
                                                        />
                                                    )
                                                }
                                            />
                                            <Text
                                                style={{
                                                    color: 'white',
                                                    marginTop: 5,
                                                    fontFamily: FONT_BOLD,
                                                    fontSize: 14,
                                                }}
                                            >
                                                {event.hasRegister
                                                    .get()
                                                    ?.want.get() !== true
                                                    ? t('register')
                                                    : t('unsubscribe')}
                                            </Text>
                                        </Pressable>
                                    ) : (
                                        <Pressable
                                            style={{
                                                justifyContent: 'center',
                                                marginHorizontal: 10,
                                                alignItems: 'center',
                                                paddingBottom: 5,
                                            }}
                                            onPress={() => {
                                                mustLoginButton(
                                                    props.navigation,
                                                    t,
                                                    () => {
                                                        if (!loadingRegister) {
                                                            if (
                                                                event.hasRegister
                                                                    .get()
                                                                    ?.want.get() ===
                                                                true
                                                            ) {
                                                                props.navigation.navigate(
                                                                    'RegisterDeleteModal',
                                                                    {
                                                                        event,
                                                                    },
                                                                );
                                                            } else {
                                                                registerAdd(
                                                                    event,
                                                                    t,
                                                                    setLoadingRegister,
                                                                );
                                                            }
                                                        }
                                                    },
                                                );
                                            }}
                                            disabled={
                                                event.hasRegister
                                                    .get()
                                                    ?.want.get() === true
                                            } // Disable the button if already registered
                                        >
                                            <PastilleView
                                                color={getColor('secondary')}
                                                size={55}
                                                icon={
                                                    !loadingRegister ? (
                                                        event.hasRegister
                                                            .get()
                                                            ?.want.get() !==
                                                        true ? (
                                                            <ArrowLeftOnRectangleIcon
                                                                height={35}
                                                                width={35}
                                                                fill={'white'}
                                                            />
                                                        ) : (
                                                            <ArrowDownOnSquareIcon
                                                                height={35}
                                                                width={35}
                                                                fill={'white'}
                                                            />
                                                        )
                                                    ) : (
                                                        <GeSpinner
                                                            size={30}
                                                            color='white'
                                                        />
                                                    )
                                                }
                                            />
                                            <Text
                                                style={{
                                                    color: 'white',
                                                    marginTop: 5,
                                                    fontFamily: FONT_BOLD,
                                                    fontSize: 14,
                                                }}
                                            >
                                                {event.hasRegister
                                                    .get()
                                                    ?.want.get() !== true
                                                    ? t('register')
                                                    : t('already_registered')}
                                            </Text>
                                        </Pressable>
                                    ))}
                                {/*(event.isInterest.get() ?? false) === false &&
                                    (event.registrable.get() === true ? (
                                        <Pressable
                                            style={{
                                                justifyContent: 'center',
                                                marginHorizontal: 10,
                                                alignItems: 'center',
                                                paddingBottom: 5,
                                            }}
                                            onPress={() => {
                                                props.navigation.navigate(
                                                    'InscripEventPage',
                                                    {
                                                        event,
                                                    },
                                                );
                                            }}
                                        >
                                            <PastilleView
                                                color={getColor('secondary')}
                                                size={55}
                                                icon={
                                                    <UserGroupIcon
                                                        height={38}
                                                        width={38}
                                                        fill={'white'}
                                                    />
                                                }
                                            />
                                            <Text
                                                style={{
                                                    color: 'white',
                                                    marginTop: 5,
                                                    fontFamily: FONT_BOLD,
                                                    fontSize: 14,
                                                }}
                                            >
                                                {t('invites')}
                                            </Text>
                                        </Pressable>
                                    ) : (
                                        <Pressable
                                            style={{
                                                marginHorizontal: 10,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                paddingBottom: 5,
                                            }}
                                            onPress={() => {
                                                props.navigation.navigate(
                                                    'TrombinoModal',
                                                    {
                                                        event,
                                                        modal: true,
                                                    },
                                                );
                                            }}
                                        >
                                            <PastilleView
                                                color={getColor('secondary')}
                                                size={55}
                                                icon={
                                                    <UserGroupIcon
                                                        height={38}
                                                        width={38}
                                                        color={'white'}
                                                    />
                                                }
                                            />
                                            <Text
                                                style={{
                                                    color: 'white',
                                                    marginTop: 5,
                                                    fontFamily: FONT_BOLD,
                                                    fontSize: 14,
                                                }}
                                            >
                                                {t('trobino')}
                                            </Text>
                                        </Pressable>
                                    ))*/}
                                {/*
                                <Pressable
                                    style={{
                                        justifyContent: 'center',
                                        marginHorizontal: 10,
                                        alignItems: 'center',
                                        paddingBottom: 5,
                                    }}
                                    onPress={() => {
                                        props.navigation.navigate(
                                            'PostEventPage',
                                            {
                                                event,
                                            },
                                        );
                                    }}
                                >
                                    <PastilleView
                                        color={getColor('secondary')}
                                        size={55}
                                        icon={
                                            <NewspaperIcon
                                                height={35}
                                                width={35}
                                                fill={'white'}
                                            />
                                        }
                                    />
                                    <Text
                                        style={{
                                            color: 'white',
                                            marginTop: 5,
                                            fontFamily: FONT_BOLD,
                                            fontSize: 14,
                                        }}
                                    >
                                        {t('timeline')}
                                    </Text>
                                </Pressable>
                                */}
                                {!loading &&
                                    event.cgv.getList().length !== 0 && (
                                        <Pressable
                                            style={{
                                                justifyContent: 'center',
                                                marginHorizontal: 10,
                                                alignItems: 'center',
                                                paddingBottom: 5,
                                            }}
                                            onPress={(e) => {
                                                Linking.openURL(
                                                    SERVER_URL +
                                                        '/file/' +
                                                        event.cgv
                                                            .getList()[0]
                                                            .path.get(),
                                                );
                                            }}
                                        >
                                            <PastilleView
                                                color={getColor('secondary')}
                                                size={55}
                                                icon={
                                                    <PaperClipIcon
                                                        height={35}
                                                        width={35}
                                                        fill={'white'}
                                                    />
                                                }
                                            />
                                            <Text
                                                style={{
                                                    color: 'white',
                                                    marginTop: 5,
                                                    fontFamily: FONT_BOLD,
                                                    fontSize: 14,
                                                }}
                                            >
                                                {t('cgv_short')}
                                            </Text>
                                        </Pressable>
                                    )}
                                {!loading && (
                                    <Pressable
                                        style={{
                                            justifyContent: 'center',
                                            marginHorizontal: 10,
                                            alignItems: 'center',
                                            paddingBottom: 5,
                                        }}
                                        onPress={() => {
                                            onShare();
                                        }}
                                    >
                                        <PastilleView
                                            color={getColor('secondary')}
                                            size={55}
                                            icon={
                                                <ShareIcon
                                                    height={35}
                                                    width={35}
                                                    fill={'white'}
                                                />
                                            }
                                        />
                                        <Text
                                            style={{
                                                color: 'white',
                                                marginTop: 5,
                                                fontFamily: FONT_BOLD,
                                                fontSize: 14,
                                            }}
                                        >
                                            {t('share')}
                                        </Text>
                                    </Pressable>
                                )}

                                {/*event.fileList.getList().length > 0 && (
                                    <Pressable
                                        style={{
                                            justifyContent: 'center',
                                            marginHorizontal: 10,
                                            alignItems: 'center',
                                            paddingBottom: 5,
                                        }}
                                        onPress={() => {
                                            props.navigation.navigate(
                                                'DocumentEventModal',
                                                {
                                                    event,
                                                },
                                            );
                                        }}
                                    >
                                        <PastilleView
                                            color={getColor('secondary')}
                                            size={55}
                                            icon={
                                                <DocumentIcon
                                                    height={35}
                                                    width={35}
                                                    fill={'white'}
                                                />
                                            }
                                        />
                                        <Text
                                            style={{
                                                color: 'white',
                                                marginTop: 5,
                                                fontFamily: FONT_BOLD,
                                                fontSize: 14,
                                            }}
                                        >
                                            {t('files')}
                                        </Text>
                                    </Pressable>
                                )*/}
                                <View
                                    style={{
                                        width: 20,
                                    }}
                                ></View>
                            </View>
                        </View>

                        <InfoEventView event={event} />
                        {event.description.get() !== undefined &&
                            event.description.get() !== '' && (
                                <View
                                    style={{
                                        marginTop: 10,
                                        paddingHorizontal: 20,
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: getColor('text'),
                                            fontFamily: FONT_BOLD,
                                            fontSize: 20,
                                        }}
                                    >
                                        {t('event.description')}
                                    </Text>
                                    <CustomTextWithLinks
                                        content={event.description.get() ?? ''}
                                        style={{
                                            color: getColor('text'),
                                            fontFamily: FONT_REGULAR,
                                        }}
                                        globalStyle={{
                                            marginTop: 10,
                                        }}
                                    />
                                </View>
                            )}
                        {event.intervenant.get() !== undefined &&
                            event.intervenant.get() !== '' && (
                                <View
                                    style={{
                                        marginTop: 10,
                                        paddingHorizontal: 20,
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: getColor('text'),
                                            fontFamily: FONT_BOLD,
                                            fontSize: 20,
                                        }}
                                    >
                                        {t('event.intervenant')}
                                    </Text>
                                    <CustomTextWithLinks
                                        content={event.intervenant.get() ?? ''}
                                        style={{
                                            color: getColor('text'),
                                            fontFamily: FONT_REGULAR,
                                        }}
                                        globalStyle={{
                                            marginTop: 10,
                                        }}
                                    />
                                </View>
                            )}
                        <View
                            style={{
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                paddingTop: 10,
                            }}
                        >
                            {event.fileList
                                .getList()
                                .map((file: any, index: any) => (
                                    <FileElementView
                                        key={file.getJTOIdentifier()}
                                        file={file}
                                        navigation={props.navigation}
                                    />
                                ))}
                        </View>
                        {((User._instance.getEvent()?.hasPermission(CAN_VOTE) ??
                            false) ||
                            User._instance.nothing) &&
                            !loadingFile &&
                            event.eventType.get()?.name.get() === 'Sondage' && (
                                <View
                                    style={{
                                        marginTop: 10,
                                        paddingHorizontal: 20,
                                    }}
                                >
                                    {event.isNotSondage() && (
                                        <Text
                                            style={{
                                                color: getColor('text'),
                                                fontFamily: FONT_BOLD,
                                                fontSize: 20,
                                            }}
                                        >
                                            {t('give_feedback')}
                                        </Text>
                                    )}
                                    <SondageView
                                        event={event as any}
                                        navigation={props.navigation}
                                    />
                                </View>
                            )}
                        {loading && (
                            <View
                                style={{
                                    display: 'flex',
                                    minHeight: height() * 0.4,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <GeSpinner color={getColor('primary')} />
                            </View>
                        )}
                        {!loadingFile && (
                            <PresentQuiz
                                nextQuiz={nextQuiz}
                                joinrealTimeQuiz={joinrealTimeQuiz}
                                joinDirectQuiz={joinDirectQuiz}
                                event={event}
                            />
                        )}
                        {/*!loadingFile &&
                            (event.eventType.get()?.name.get() === 'Quiz' ||
                                event.eventType.get()?.name.get() ===
                                    'Nuage de mot') &&
                            (event.questionList.getList().length === 0 &&
                            event.questionRealTimeList.getList().length ===
                                0 ? (
                                <View
                                    style={{
                                        display: 'flex',
                                        minHeight: height() * 0.4,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: getColor('text'),
                                            paddingHorizontal: 20,
                                            fontFamily: FONT_REGULAR,
                                            width: width(),
                                            textAlign: 'center',
                                        }}
                                    >
                                        {t('quiz_not_avaible')}
                                    </Text>
                                </View>
                            ) : (
                                <View
                                    style={{
                                        marginTop: 20,
                                        paddingHorizontal: 20,
                                        paddingVertical: 20,
                                        height: '100%',
                                        marginHorizontal: 20,
                                        backgroundColor: getColor('card'),
                                        flex: 1,
                                        borderRadius: 25,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <View
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flex: 1,
                                        }}
                                    >
                                        {event.hasResponse.get() === null ? (
                                            <ClockIcon
                                                style={{
                                                    paddingVertical: 15,
                                                }}
                                                height={75}
                                                width={75}
                                                color={getColor('secondary')}
                                            />
                                        ) : (
                                            <HandThumbUpIcon
                                                style={{
                                                    paddingVertical: 15,
                                                }}
                                                height={75}
                                                width={75}
                                                color={getColor('success')}
                                            />
                                        )}
                                        <Text
                                            style={{
                                                fontFamily: FONT_BOLD,
                                                fontSize: 25,
                                                paddingTop: 15,
                                                color: getColor('text'),
                                                textAlign: 'center',
                                            }}
                                        >
                                            {event.hasResponse.get() === null
                                                ? t('waiting')
                                                : t('thank_answer')}
                                        </Text>
                                        <Text
                                            style={{
                                                fontFamily: FONT_REGULAR,
                                                paddingVertical: 15,
                                                textAlign: 'center',
                                                color: getColor('text'),
                                                fontSize: 16,
                                            }}
                                        >
                                            {event.hasResponse.get() === null
                                                ? t('insite_answer')
                                                : t('after_answer')}
                                        </Text>
                                    </View>
                                    <Pressable
                                        onPress={() => {
                                            if (
                                                event.eventType
                                                    .get()
                                                    ?.name.get() === 'Quiz'
                                            ) {
                                                nextQuiz();
                                            } else {
                                                joinrealTimeQuiz();
                                            }
                                        }}
                                        style={{
                                            marginVertical: 10,
                                            width: '100%',
                                            borderRadius: 30,
                                            paddingVertical: 15,
                                            paddingHorizontal: 20,
                                            backgroundColor:
                                                getColor('secondary'),
                                            borderWidth: 1,
                                            borderColor: getColor('secondary'),
                                        }}
                                    >
                                        <Text
                                            adjustsFontSizeToFit
                                            numberOfLines={1}
                                            style={{
                                                fontSize: 18,
                                                color: 'white',
                                                fontFamily: FONT_BOLD,
                                                textAlign: 'center',
                                            }}
                                        >
                                            {t('start_quiz')}
                                        </Text>
                                    </Pressable>
                                </View>
                            ))*/}
                        <UnderEventPage
                            navigation={props.navigation}
                            event={event}
                        />
                        <View
                            style={{
                                height:
                                    NAVIGATION_BAR_HEIGHT +
                                    STATUS_BAR_HEIGHT +
                                    50,
                            }}
                        ></View>
                    </View>
                </View>
            </Header>

            <View
                style={{
                    position: 'absolute',
                    bottom: 10,
                    right: 10,
                }}
            >
                <CloseAllButton
                    onPress={() => {
                        props.navigation.popToTop();
                    }}
                />
            </View>
        </View>
    );
};
