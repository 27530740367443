import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    ActivityIndicator,
    FlatList,
    FlatListProps,
    GestureResponderEvent,
    ListRenderItem,
    NativeScrollEvent,
    NativeSyntheticEvent,
    Pressable,
    View,
} from 'react-native';
//import { RefreshControl, ScrollView } from 'react-native-gesture-handler';
import { Text } from 'react-native';
import React from 'react';
import User from '../../object/User';
import { styles } from '../../utils/styles';
import { marginBottom, marginTop } from '../../utils/tailwinds';
import { getColor } from '../../utils/theme';
import { height, isCloseToBottom, width } from '../../utils/utils';
import { PostView } from '../PostView';
import {
    HEADER_BONUS_HEIGHT,
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../../utils/screen';
import { useTranslation } from 'react-i18next';
import { GeSpinner } from '../GeSpinner';
import Post from '../../object/Post';
import { Header } from '../Header';
import { UserIcon } from 'react-native-heroicons/solid';
import { CartoucheButton } from '../CartoucheButton';
import useJTOState from '../../utils/jto/useJTOState';
import { CloseAllButton } from '../CloseAllButton';
export const PostProfilePage = (props: any) => {
    const { t } = useTranslation();

    const user = useJTOState((props as any).route.params.user as User);
    user.event = User._instance.event;

    const myPostList = useJTOState(
        user.getEvent()!.getInvite(user)!.myPostList,
    );
    const [refreshing, setRefreshing] = useState(false);

    const onRefresh = () => {
        setRefreshing(false);
        myPostList.reset();
        myPostList.refetch({
            id: user.getEvent()!.getJTOIdentifier(),
            id_user: user.getJTOIdentifier(),
        });
    };

    useEffect(() => {
        myPostList.refetch({
            id: user.getEvent()!.getJTOIdentifier(),
            id_user: user.getJTOIdentifier(),
        });
    }, []);

    const elemList: (string | Post)[] = [];

    elemList.push('first');

    elemList.push(...myPostList.getList());

    if (myPostList.isLoading() || !myPostList.isComplite()) {
        elemList.push('loading');
    }
    if (elemList.length === 1) {
        elemList.push('nothing');
    }

    elemList.push('end');

    const keyExtractor = useCallback(
        (_: any, index: any) => index.toString(),
        [],
    );

    const renderItem = useCallback<ListRenderItem<string | Post>>(
        ({ item, index }) => {
            if (typeof item === 'string') {
                if (item === 'first') {
                    return (
                        <View
                            style={{
                                height: HEADER_BONUS_HEIGHT + 20,
                            }}
                        ></View>
                    );
                } else if (item === 'end') {
                    return (
                        <View
                            style={{
                                height: TAB_HEIGHT + NAVIGATION_BAR_HEIGHT,
                                width: width(),
                            }}
                        ></View>
                    );
                } else if (item === 'loading') {
                    if (elemList.length === 3) {
                        return (
                            <View
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height:
                                        height() -
                                        TAB_HEIGHT -
                                        STATUS_BAR_HEIGHT -
                                        NAVIGATION_BAR_HEIGHT,
                                    justifyContent: 'center',
                                    backgroundColor: getColor('background'),
                                }}
                            >
                                <GeSpinner color={getColor('primary')} />
                            </View>
                        );
                    } else {
                        return (
                            <View
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingVertical: 20,
                                    justifyContent: 'center',
                                    backgroundColor: getColor('background'),
                                }}
                            >
                                <GeSpinner color={getColor('primary')} />
                            </View>
                        );
                    }
                } else {
                    if (user.isMe()) {
                        return (
                            <View
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingHorizontal: 20,
                                    height:
                                        height() -
                                        TAB_HEIGHT -
                                        STATUS_BAR_HEIGHT -
                                        NAVIGATION_BAR_HEIGHT -
                                        150,
                                    backgroundColor: getColor('background'),
                                }}
                            >
                                <Text
                                    style={{
                                        color: getColor('text'),
                                        textAlign: 'center',
                                    }}
                                >
                                    {t('profile.me.no_post')}
                                </Text>
                            </View>
                        );
                    } else {
                        return (
                            <View
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingHorizontal: 20,
                                    height:
                                        height() -
                                        TAB_HEIGHT -
                                        STATUS_BAR_HEIGHT -
                                        NAVIGATION_BAR_HEIGHT -
                                        150,
                                    backgroundColor: getColor('background'),
                                }}
                            >
                                <Text
                                    style={{
                                        color: getColor('text'),
                                        textAlign: 'center',
                                    }}
                                >
                                    {t('profile.no_post')}
                                </Text>
                            </View>
                        );
                    }
                }
            } else {
                return (
                    <PostView
                        key={item.getJTOIdentifier()}
                        post={item}
                        navigation={props.navigation}
                    />
                );
            }
        },
        [],
    );
    return (
        <View
            style={{
                width: width(),
                paddingBottom: 0,
                margin: 'auto',
                flex: 1,
                backgroundColor: getColor('background'),
            }}
        >
            <Header
                title={user.getDisplayName()}
                logo={user.avatar.get() ?? ''}
                fallback={
                    <UserIcon
                        color={'white'}
                        size={35}
                    />
                }
                line={1}
                backButton
                modal
                full
                navigation={props.navigation}
                rounded={(user.avatar.get() ?? '') !== ''}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
            >
                <FlatList
                    data={elemList}
                    style={{
                        backgroundColor: getColor('background'),
                        marginTop: -HEADER_BONUS_HEIGHT,
                        flex: 1,
                    }}
                    keyboardDismissMode='on-drag'
                    // @ts-ignore
                    onScroll={({ nativeEvent }) => {
                        if (isCloseToBottom(nativeEvent)) {
                            myPostList.more({
                                id: user.getEvent()!.getJTOIdentifier(),
                                id_user: user.getJTOIdentifier(),
                            });
                        }
                    }}
                    scrollEventThrottle={1}
                    onMomentumScrollEnd={({ nativeEvent }) => {
                        if (isCloseToBottom(nativeEvent)) {
                            myPostList.more({
                                id: user.getEvent()!.getJTOIdentifier(),
                                id_user: user.getJTOIdentifier(),
                            });
                        }
                    }}
                    /*refreshControl={
                        <RefreshControl
                            progressViewOffset={HEADER_BONUS_HEIGHT}
                            colors={[getColor('primary')]}
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                        />
                    }*/
                    renderItem={renderItem}
                    keyExtractor={keyExtractor}
                ></FlatList>
            </Header>
            <CloseAllButton
                onPress={() => {
                    props.navigation.popToTop();
                }}
            />
        </View>
    );
};
