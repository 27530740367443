import { useEffect, useRef, useState } from 'react';
import {
    ActivityIndicator,
    AppState,
    BackHandler,
    FlatList,
    Image,
    Keyboard,
    KeyboardAvoidingView,
    Platform,
    Pressable,
    SafeAreaView,
    Text,
    View,
} from 'react-native';
import React from 'react';
//import { FlatList, ScrollView } from 'react-native-gesture-handler';
import { BottomMessagePage } from '../component/message/BottomMessagePage';
import { TopMessagePage } from '../component/message/TopMessagePage';
import JTOListFetch from '../object/JTOListFetch';
import { Message } from '../object/Message';
import User from '../object/User';
import { SERVER_URL } from '../utils/constants';
import { NAVIGATION_BAR_HEIGHT, STATUS_BAR_HEIGHT } from '../utils/screen';
import { styles } from '../utils/styles';
import { getColor } from '../utils/theme';
import {
    closeAction,
    isCloseToBottom,
    openAction,
    useKeyboardVisible,
    width,
} from '../utils/utils';
import { MessageView } from '../component/message/MessageView';
import { Header } from '../component/Header';
import ChatGroup from '../object/ChatGroup';
import { UserGroupIcon, UserIcon } from 'react-native-heroicons/solid';
import { HTTPUtils } from 'mp-ts-http';
import { t } from 'i18next';
import { NavigationRouteContext, useIsFocused } from '@react-navigation/native';
import Event from '../object/Event';
import useJTOState from '../utils/jto/useJTOState';

function compareDates5M(date1: string, date2: string) {
    const date1InMs = new Date(date1).getTime();
    const date2InMs = new Date(date2).getTime();

    const diffInMs = date2InMs - date1InMs;

    const fiveMinutesInMs = 5 * 60 * 1000;

    return diffInMs > fiveMinutesInMs;
}

export interface IMessagePageProps {
    navigation: any;
    params: { [key: string]: any };
}

export const WordPage = (props: IMessagePageProps) => {
    const { navigation } = props;

    const event = useJTOState(User._instance.getEvent() ?? new Event());
    const messageList = useJTOState(
        event.dirigeant.get()?.messageList ?? new JTOListFetch<Message>(''),
    );
    const dirigeant = useJTOState(event.dirigeant.get() ?? new User());
    User._currentMessageList = messageList;

    const isFocused = useIsFocused();
    const appState = useRef(AppState.currentState);
    const [appStateVisible, setAppStateVisible] = useState(appState.current);

    useEffect(() => {
        const subscription = AppState.addEventListener(
            'change',
            (nextAppState) => {
                if (
                    appState.current.match(/inactive|background/) &&
                    nextAppState === 'active'
                ) {
                    openAction();
                } else {
                    closeAction();
                }

                appState.current = nextAppState;
                setAppStateVisible(appState.current);
            },
        );

        return () => {
            subscription.remove();
        };
    }, []);

    useEffect(() => {
        dirigeant.messageList.reset();
        dirigeant.messageList.refetch({
            id: dirigeant.getJTOIdentifier(),
        });

        setTimeout(() => {
            messageList.more({
                id: dirigeant.getJTOIdentifier(),
            });
        }, 1000);
    }, []);

    const elementList = [];
    for (let i = 0; i < messageList.getList().length; i++) {
        const cc = messageList.getList()[i];
        elementList.push(cc);
        if (i !== messageList.getList().length - 1) {
            const after = messageList.getList()[i + 1];
            if (compareDates5M(after.date.get() ?? '', cc.date.get() ?? '')) {
                elementList.push(new Date(cc.date.get() ?? ''));
            }
        }
    }

    const message = new Message();
    message.setJTOIdentifier('abc');
    message.content.set(event.word.get());
    message.date.set(new Date(0));
    elementList.push(message);

    if (messageList.isLoading() || !messageList.isComplite()) {
        elementList.push('loading' as any);
    } else {
        elementList.push('top' as any);
    }

    // ADD TO EACH CHANGE OF PAGE

    useEffect(() => {
        if (isFocused) {
            openAction();
        } else {
            closeAction();
        }
    }, [isFocused]);

    return (
        <KeyboardAvoidingView
            style={styles.page}
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        >
            {
                <Header
                    line={1}
                    rounded={true}
                    backButton={true}
                    navigation={props.navigation}
                    title={t('word')}
                    onBack={closeAction}
                    onPresslogo={() => {
                        navigation.navigate('MenuProfileModal', {
                            user: event.dirigeant.get(),
                        });
                    }}
                    logo={event.logo.get() ?? ''}
                    after={
                        <View
                            style={{
                                height: 10,
                            }}
                        ></View>
                    }
                >
                    <SafeAreaView
                        style={{
                            flex: 1,
                            marginTop: -300,
                            paddingTop: 20,
                            backgroundColor: getColor('background'),
                        }}
                    >
                        <FlatList<Message>
                            // @ts-ignore
                            onScroll={({ nativeEvent }) => {
                                if (isCloseToBottom(nativeEvent)) {
                                    messageList.more({
                                        id: dirigeant.getJTOIdentifier(),
                                    });
                                }
                                Keyboard.dismiss();
                            }}
                            onMomentumScrollEnd={({ nativeEvent }) => {
                                if (isCloseToBottom(nativeEvent)) {
                                    messageList.more({
                                        id: dirigeant.getJTOIdentifier(),
                                    });
                                }
                            }}
                            scrollEventThrottle={1}
                            style={{
                                flex: 1,
                            }}
                            data={elementList}
                            renderItem={({ item, index }) => {
                                return (
                                    <MessageView
                                        elem={dirigeant}
                                        type={'user'}
                                        item={item}
                                        index={index}
                                        listLength={elementList.length}
                                        navigation={navigation}
                                    />
                                );
                            }}
                            inverted={true}
                        ></FlatList>
                    </SafeAreaView>
                </Header>
            }
        </KeyboardAvoidingView>
    );
};
