//import LottieView from 'lottie-react-native';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, View } from 'react-native';
import React from 'react';
/*import Animated, {
    runOnJS,
    useAnimatedStyle,
    withTiming,
} from 'react-native-reanimated';*/
import { confettiAnim } from '../anim/confetti';
import { solarAnim } from '../anim/solar';
import User from '../object/User';
import {
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import { getColor } from '../utils/theme';
import { height, hexToRgb, width } from '../utils/utils';
import { CartoucheButton } from './CartoucheButton';
import { LevelFocusView } from './LevelFocusView';

export let startConfetti = () => {
    // TO BE OVERWRITTEN
};

export const Confetti = () => {
    const { t } = useTranslation();

    const [show, setShow] = useState(false);
    const [showAlpha, setShowAlpha] = useState(false);

    function hide() {
        setShow(false);
    }

    /*const opacityStyle = useAnimatedStyle(() => {
        return {
            opacity: withTiming(
                showAlpha ? 1 : 0,
                {
                    duration: 300,
                },
                () => {
                    if (!showAlpha) {
                        runOnJS(hide)();
                    }
                },
            ),
        };
    });*/

    startConfetti = () => {
        setShow(true);
        setShowAlpha(true);
    };

    return show ? (
        <Pressable
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: width(),
                marginLeft: 'auto',
                marginRight: 'auto',
                backgroundColor: 'rgba(20, 20, 20, 0.3)',
            }}
            onPress={() => {
                setShowAlpha(false);
            }}
        >
            {/*<Animated.View
                style={[
                    {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        backgroundColor: getColor('primary') + 'CC',
                    },
                    opacityStyle,
                ]}
            >*/}
            {/*<LottieView
                style={{
                    position: 'absolute',
                    top: -width() * 0.1,
                    left: 0,
                    width: Math.round(width() * 1.5),
                    height: Math.round(width() * 1.5) + 50,
                }}
                source={solarAnim()}
                autoPlay
                loop={true}
            />*/}
            <View
                style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: width(),
                }}
            >
                <LevelFocusView
                    shadow={true}
                    navigation={null}
                    user={User._instance}
                />
                <View
                    style={{
                        bottom: 10,
                        right: 10,
                        position: 'absolute',
                    }}
                >
                    <CartoucheButton
                        height={50}
                        onPress={() => {
                            hide();
                        }}
                        fontSize={16}
                        title={t('close')}
                        color={getColor('secondary')}
                    />
                </View>
            </View>
        </Pressable>
    ) : (
        <></>
    );
};
{
    /*</Animated.View>*/
}
{
    /*<LottieView
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                }}
                source={confettiAnim()}
                autoPlay
                loop={false}
            />*/
}
