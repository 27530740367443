import {
    Clipboard,
    InteractionManager,
    Linking,
    Pressable,
    RefreshControl,
    ScrollView,
    Text,
    View,
} from 'react-native';
import User from '../object/User';
import React, { useCallback, useEffect, useState } from 'react';
import { HTTPUtils } from 'mp-ts-http';
import { getErrorMessage } from 'mp-ts-error';
import Event, { compareEvent } from '../object/Event';
import { Image } from 'react-native';
import {
    APP_EVENT_ID,
    FONT_BOLD,
    FONT_MEDIUM,
    FONT_REGULAR,
    getColor,
    YOUTUBE_API,
} from '../utils/theme';
import { EventTopPresentation } from '../component/EventTopPresentation';
import {
    HEADER_BONUS_HEIGHT,
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import CountDown from 'react-native-countdown-component';
import { IPageProps } from './IPageProps';
import Cartouche from '../svg/Cartouche';
import { height, openAction, width } from '../utils/utils';
import { SERVER_URL } from '../utils/constants';
import { Header } from '../component/Header';
import {
    BellIcon,
    ChatBubbleOvalLeftEllipsisIcon,
    ChatBubbleOvalLeftIcon,
    DocumentIcon,
    PlayCircleIcon,
    MapPinIcon,
    TrophyIcon,
    UserGroupIcon,
    HeartIcon,
    PaperClipIcon,
    QrCodeIcon,
} from 'react-native-heroicons/solid';
import Pastille from '../svg/Pastille';
import Point from '../svg/Point';
import Rank from '../svg/Rank';
import Service from '../svg/Service';
import Bonheur from '../svg/Bonheur';
//import { ScrollView } from 'react-native-gesture-handler';
import { t } from 'i18next';
import { PastilleView } from '../component/PastilleView';
import { profilStyle } from './ProfilePage';
import { ScheduleContentEvent } from '../component/ScheduleContentEvent';
import { ScheduleEvent } from '../component/ScheduleEvent';
import { TimerView } from '../component/TimerView';
//import messaging from '@react-native-firebase/messaging';
import { InfoEventView } from '../component/InfoEventView';
import {
    PhoneIcon,
    PhoneXMarkIcon,
    UserIcon,
} from 'react-native-heroicons/solid';
//import LottieView from 'lottie-react-native';
import { get, getRestore, post } from '../utils/http';
import { CartoucheButton } from '../component/CartoucheButton';
import { GeSpinner } from '../component/GeSpinner';
import BorderAnimation from '../component/BorderAnimation';
import { ProgressLine } from '../component/ProgressLine';
import { chatAnim } from '../anim/chat';
//import { storage } from '../../App';
import { CountDownView } from '../component/CountDownView';
//import YouTube from 'react-native-youtube';
import { BEFORE_LOGO } from '../utils/config';
import useNavigationJTOState from '../utils/useNavigationJTOState';
import JTOInstance from '../utils/jto/JTOInstance';
import { applyResult } from '../utils/applyResult';
import EventTiny, {
    compareEventTiny,
} from '../object/compressed/event/EventTiny';
import useJTOState from '../utils/jto/useJTOState';
import JTOListFetch from '../object/JTOListFetch';
import Post from '../object/Post';
import { HeartSection } from '../component/HeartSection';
import ChatGroup from '../object/ChatGroup';
import { CustomTextWithLinks } from '../component/CustomTextWithLinks';
import Lottie from 'lottie-react';
import { AdvertSection } from '../component/AdvertSection';

export const HomePage = (props: IPageProps) => {
    const event = useNavigationJTOState(
        User._instance.getEvent()!,
        props.navigation,
        () => {
            // onRefresh()
        },
        () => {
            // event.mySubEventList.reset();
            // event.eventType.set(null);
            // event.fileList.setList([]);
        },
    );

    const [loading, setLoading] = useState(true);
    const [newMessage, setNewMessage] = useState(false);
    const [showVideo, setShowVideo] = useState(false);

    const favoriteList = useJTOState(
        new JTOListFetch<EventTiny | Post>('/event/favorite/list', [
            EventTiny,
            Post,
        ]),
    );

    // {
    //    id: event.getJTOIdentifier(),
    // }

    useEffect(() => {
        const onMessage = (message: any) => {
            if (message !== undefined && message !== null) {
                const data = message.data;
                if (data !== undefined) {
                    const type = data.type;
                    const action = data.action;
                    if (type === 'Message') {
                        if (action === 'ChatGroup') {
                            const groupJson = JSON.parse(data.group);
                            props.navigation.navigate('messages');
                            const group = new ChatGroup();
                            group.applyData(groupJson);
                            props.navigation.navigate('MessagePage', {
                                group,
                            });
                            User._instance.setCurrentChatType('ChatGroup');
                            User._instance.setCurrentChatId(
                                group.getJTOIdentifier(),
                            );
                            openAction();
                        } else {
                            const userJson = JSON.parse(data.user);
                            const user = new User();
                            user.applyData(userJson);
                            props.navigation.navigate('messages');
                            props.navigation.navigate('MessagePage', {
                                user,
                            });
                            User._instance.setCurrentChatType('User');
                            User._instance.setCurrentChatId(
                                user.getJTOIdentifier(),
                            );
                            openAction();
                        }
                    } else if (type === 'Event') {
                        if (action === 'Open') {
                            const eventJson = JSON.parse(data.event);
                            const eventType = new Event();
                            eventType.applyData(eventJson);
                            props.navigation.push('SubEventModal', {
                                event: eventType,
                            });
                        }
                    }
                }
            }
        };
        /*messaging().onNotificationOpenedApp((message) => {
            onMessage(message);
        });
        messaging()
            .getInitialNotification()
            .then((remoteMessage) => {
                onMessage(remoteMessage);
            })
            .catch((err) => {
                // NO OP
            });*/

        post('/user/event/confirm', {
            id: User._instance.getEvent()?.getJTOIdentifier() ?? '',
        })
            .then((res) => {
                // NO OP
            })
            .catch((err) => {
                // NO OP
            });
    }, []);

    const onRefresh = useCallback(() => {
        event.mySubEventList.reset();
        event.mySubEventList.refetch({
            event: event.getJTOIdentifier(),
        });
        getRestore(
            '/event/get',
            {
                id: event.getJTOIdentifier(),
            },
            async (res: any) => {
                if (HTTPUtils.isSuccess(res)) {
                    const e = HTTPUtils.getResult(res);
                    applyResult(e, event, true);

                    try {
                        const resultHas = await get(
                            '/event/hasInterestOrFavorite',
                            {
                                id: event.getJTOIdentifier(),
                            },
                        );
                        if (HTTPUtils.isSuccess(resultHas)) {
                            const has = HTTPUtils.getResult(resultHas);
                            event.hasInterest.set(has.Event[0]['has_interest']);
                            event.hasFavorite.set(has.Event[0]['has_favorite']);
                        } else {
                        }
                    } catch (err) {
                        // TODO: error
                    }
                    setLoading(false);
                } else {
                    // TODO: error
                }
            },
            (err: any) => {
                // TODO: error
            },
            (res: any) => {
                event.eventType.set(null);
                event.fileList.setList([]);
            },
        );
    }, []);

    useEffect(() => {
        InteractionManager.runAfterInteractions(() => {
            onRefresh();

            /*storage.getBool(
                'dirigeantword' + event.getJTOIdentifier(),
                (err, value) => {
                    if (
                        err ||
                        value === null ||
                        value === undefined ||
                        value === false
                    ) {
                        setNewMessage(true);
                    }
                },
            );*/
        });
    }, []);

    useEffect(() => {
        /*messaging()
            .requestPermission()
            .then((res) => {
                messaging()
                    .getToken()
                    .then((token) => {
                        User._instance.token = token;
                        post('/notif/create', {
                            token,
                            id: APP_EVENT_ID,
                        })
                            .then((result) => {
                                post('/notif/update', {
                                    token: result,
                                    id_user: User._instance.getJTOIdentifier(),
                                })
                                    .then((response) => {
                                        // TODO
                                    })
                                    .catch((err) => {
                                        // TODO
                                    });
                            })
                            .catch((err) => {
                                // TODO
                            });
                    })
                    .catch((err) => {
                        User._instance.token = '';
                        // TODO
                    });
            });*/
    }, []);

    const date = new Date();
    let pastSubEvent = [] as EventTiny[];
    for (const subEvent of event.mySubEventList.getList()) {
        if (subEvent.isBeforeNow1Day()) {
            pastSubEvent.push(subEvent);
        }
    }
    pastSubEvent = pastSubEvent.sort((a, b) => {
        return compareEventTiny(a, b, -1);
    });

    const nextOrCurrentEvent: EventTiny[] = [];
    for (const subEvent of event.mySubEventList.getList()) {
        if (subEvent.isNow()) {
            nextOrCurrentEvent.push(subEvent);
        }
    }
    if (nextOrCurrentEvent.length === 0) {
        // get the closest event
        let subEventFound: EventTiny | null = null;
        for (const subEvent of event.mySubEventList.getList()) {
            if (subEvent.isAfterNow() && subEvent.isNotSondage()) {
                if (
                    subEvent.getBetterDate() !== '' &&
                    subEvent.getBetterDate() !== undefined &&
                    subEvent.getBetterDate() !== null
                ) {
                    if (subEventFound === null) {
                        subEventFound = subEvent;
                    } else {
                        if (
                            new Date(
                                subEventFound.getBetterDate() ?? '',
                            ).getTime() >
                            new Date(subEvent.getBetterDate() ?? '').getTime()
                        ) {
                            subEventFound = subEvent;
                        }
                    }
                }
            }
        }
        if (subEventFound !== null) {
            nextOrCurrentEvent.push(subEventFound);
        }
    }

    useEffect(() => {
        setShowVideo(false);
        setTimeout(() => {
            /*storage.getInt(
                'video_' + event.getJTOIdentifier(),
                (err, value) => {
                    if (
                        err ||
                        value === null ||
                        value === undefined ||
                        value < 3
                    ) {
                        setShowVideo(true);
                        storage.setInt(
                            'video_' + event.getJTOIdentifier(),
                            (value ?? 0) + 1,
                        );
                    } else {
                        setShowVideo(false);
                        // props.navigation.navigate('WelcomeModal', {});
                    }
                },
            );*/
        }, 500);
    }, []);

    // return <View></View>

    return (
        <View
            style={{
                backgroundColor: getColor('background'),
                flex: 1,
                width: '100%',
            }}
        >
            <Header
                title={event.name.get() ?? ''}
                logo={event.logo.get() ?? ''}
                beforeLogo={BEFORE_LOGO}
                full
                navigation={props.navigation}
                after={<View></View>}
            >
                <ScrollView
                    style={{
                        flex: 1,
                    }}
                    // @ts-ignore
                    refreshControl={
                        <RefreshControl
                            colors={[getColor('primary')]}
                            refreshing={false}
                            onRefresh={onRefresh}
                        />
                    }
                >
                    <View
                        style={{
                            width: '100%',
                            position: 'relative',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <View
                            style={{
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                paddingHorizontal: 10,
                                justifyContent: 'flex-start',
                                width: '100%',
                                paddingTop: 10,
                                backgroundColor: getColor('primary'),
                            }}
                        >
                            <Pressable
                                style={{
                                    marginHorizontal: 10,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: 10,
                                }}
                                onPress={() => {
                                    props.navigation.navigate('History');
                                }}
                            >
                                <PastilleView
                                    color={getColor('secondary')}
                                    size={55}
                                    icon={
                                        <BellIcon
                                            height={40}
                                            width={40}
                                            color={'white'}
                                        />
                                    }
                                />
                                <Text
                                    numberOfLines={1}
                                    adjustsFontSizeToFit
                                    style={{
                                        textAlign: 'center',
                                        color: 'white',
                                        marginTop: 5,
                                        flexWrap: 'wrap',
                                        fontFamily: FONT_BOLD,
                                        fontSize: 13,
                                    }}
                                >
                                    {t('notif')}
                                </Text>
                            </Pressable>
                            {/*(
                            <Pressable
                                style={{
                                    marginHorizontal: 10,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: 10,
                                }}
                                onPress={() => {
                                    props.navigation.navigate('TrombinoPage', {
                                        event,
                                    });
                                }}
                            >
                                <PastilleView
                                    color={getColor('secondary')}
                                    size={55}
                                    icon={
                                        <UserGroupIcon
                                            height={38}
                                            width={38}
                                            color={'white'}
                                        />
                                    }
                                />
                                <Text
                                    numberOfLines={1}
                                    adjustsFontSizeToFit
                                    style={{
                                        textAlign: 'center',
                                        color: 'white',
                                        marginTop: 5,
                                        fontFamily: FONT_BOLD,
                                        fontSize: 13,
                                    }}
                                >
                                    {t('invites')}
                                </Text>
                            </Pressable>
                            )*/}
                            {/*  
                            <Pressable
                                style={{
                                    marginHorizontal: 10,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: 10,
                                }}
                                onPress={() => {
                                    props.navigation.navigate('RankPage', {
                                        event,
                                    });
                                }}
                            >
                                <PastilleView
                                    color={getColor('secondary')}
                                    size={55}
                                    icon={
                                        <TrophyIcon
                                            height={33}
                                            width={33}
                                            color={'white'}
                                        />
                                    }
                                />
                                <Text
                                    numberOfLines={1}
                                    adjustsFontSizeToFit
                                    style={{
                                        textAlign: 'center',
                                        color: 'white',
                                        marginTop: 5,
                                        fontFamily: FONT_BOLD,
                                        fontSize: 13,
                                    }}
                                >
                                    {t('rank')}
                                </Text>
                            </Pressable>
                            */}
                            {event.hasInterest.get() && (
                                <Pressable
                                    style={{
                                        marginHorizontal: 10,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginBottom: 10,
                                    }}
                                    onPress={() => {
                                        props.navigation.navigate(
                                            'InterestPage',
                                            {
                                                event,
                                            },
                                        );
                                    }}
                                >
                                    <PastilleView
                                        color={getColor('secondary')}
                                        size={55}
                                        icon={
                                            <MapPinIcon
                                                height={33}
                                                width={33}
                                                color={'white'}
                                            />
                                        }
                                    />
                                    <Text
                                        adjustsFontSizeToFit
                                        numberOfLines={2}
                                        style={{
                                            textAlign: 'center',

                                            color: 'white',
                                            marginTop: 5,
                                            fontFamily: FONT_BOLD,
                                            fontSize: 13,
                                        }}
                                    >
                                        {t('interest')}
                                    </Text>
                                </Pressable>
                            )}

                            {/*  
                            <Pressable
                                style={{
                                    marginHorizontal: 10,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: 10,
                                }}
                                onPress={() => {
                                    props.navigation.navigate('ExposantPage', {
                                        event,
                                    });
                                }}
                            >
                                <PastilleView
                                    color={getColor('secondary')}
                                    size={55}
                                    icon={
                                        <UserGroupIcon
                                            height={33}
                                            width={33}
                                            color={'white'}
                                        />
                                    }
                                />
                                <Text
                                    adjustsFontSizeToFit
                                    numberOfLines={2}
                                    style={{
                                        textAlign: 'center',
                                        color: 'white',
                                        marginTop: 5,
                                        fontFamily: FONT_BOLD,
                                        fontSize: 13,
                                    }}
                                >
                                    {t('exposants')}
                                </Text>
                            </Pressable>
                            */}
                            {!loading && event.cgv.getList().length !== 0 && (
                                <Pressable
                                    style={{
                                        justifyContent: 'center',
                                        marginHorizontal: 10,
                                        alignItems: 'center',
                                        marginBottom: 10,
                                    }}
                                    onPress={(e) => {
                                        Linking.openURL(
                                            SERVER_URL +
                                                '/file/' +
                                                event.cgv
                                                    .getList()[0]
                                                    .path.get(),
                                        );
                                    }}
                                >
                                    <PastilleView
                                        color={getColor('secondary')}
                                        size={55}
                                        icon={
                                            <PaperClipIcon
                                                height={35}
                                                width={35}
                                                fill={'white'}
                                            />
                                        }
                                    />
                                    <Text
                                        style={{
                                            color: 'white',
                                            marginTop: 5,
                                            fontFamily: FONT_BOLD,
                                            fontSize: 14,
                                        }}
                                    >
                                        {t('cgv_short')}
                                    </Text>
                                </Pressable>
                            )}

                            {event.hasFavorite.get() && (
                                <Pressable
                                    style={{
                                        marginHorizontal: 10,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginBottom: 10,
                                    }}
                                    onPress={() => {
                                        props.navigation.navigate(
                                            'FavoritePage',
                                            {
                                                event,
                                            },
                                        );
                                    }}
                                >
                                    <PastilleView
                                        color={getColor('secondary')}
                                        size={55}
                                        icon={
                                            <HeartIcon
                                                height={33}
                                                width={33}
                                                color={'white'}
                                            />
                                        }
                                    />
                                    <Text
                                        adjustsFontSizeToFit
                                        numberOfLines={2}
                                        style={{
                                            textAlign: 'center',

                                            color: 'white',
                                            marginTop: 5,
                                            fontFamily: FONT_BOLD,
                                            fontSize: 13,
                                        }}
                                    >
                                        {t('favorites')}
                                    </Text>
                                </Pressable>
                            )}

                            {event.dirigeant.get() !== null &&
                                (event.word.get() ?? '') !== '' && (
                                    <Pressable
                                        style={{
                                            marginHorizontal: 10,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginBottom: 10,
                                        }}
                                        onPress={() => {
                                            props.navigation.navigate(
                                                'WordPage',
                                                {
                                                    event,
                                                },
                                            );
                                        }}
                                    >
                                        <PastilleView
                                            color={getColor('secondary')}
                                            size={55}
                                            icon={
                                                <ChatBubbleOvalLeftEllipsisIcon
                                                    height={38}
                                                    width={38}
                                                    color={'white'}
                                                />
                                            }
                                        />
                                        <Text
                                            adjustsFontSizeToFit
                                            numberOfLines={2}
                                            style={{
                                                textAlign: 'center',

                                                color: 'white',
                                                marginTop: 5,
                                                fontFamily: FONT_BOLD,
                                                fontSize: 13,
                                            }}
                                        >
                                            {t('word')}
                                        </Text>
                                    </Pressable>
                                )}
                            {!User._instance.nothing && (
                                <Pressable
                                    style={{
                                        marginHorizontal: 10,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginBottom: 10,
                                    }}
                                    onPress={() => {
                                        props.navigation.navigate(
                                            'ScanBadgePage',
                                            {
                                                event,
                                            },
                                        );
                                    }}
                                >
                                    <PastilleView
                                        color={getColor('secondary')}
                                        size={55}
                                        icon={
                                            <QrCodeIcon
                                                height={38}
                                                width={38}
                                                color={'white'}
                                            />
                                        }
                                    />
                                    <Text
                                        numberOfLines={1}
                                        adjustsFontSizeToFit
                                        style={{
                                            textAlign: 'center',

                                            color: 'white',
                                            marginTop: 5,
                                            fontFamily: FONT_BOLD,
                                            fontSize: 13,
                                        }}
                                    >
                                        {t('scan_badge')}
                                    </Text>
                                </Pressable>
                            )}

                            {event.video.get() !== null &&
                                (event.video.get() ?? '') !== '' && (
                                    <Pressable
                                        style={{
                                            marginHorizontal: 10,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginBottom: 10,
                                        }}
                                        onPress={() => {
                                            props.navigation.navigate(
                                                'VideoPage',
                                                {
                                                    event,
                                                },
                                            );
                                        }}
                                    >
                                        <PastilleView
                                            color={getColor('secondary')}
                                            size={55}
                                            icon={
                                                <PlayCircleIcon
                                                    height={38}
                                                    width={38}
                                                    color={'white'}
                                                />
                                            }
                                        />
                                        <Text
                                            adjustsFontSizeToFit
                                            numberOfLines={2}
                                            style={{
                                                textAlign: 'center',

                                                color: 'white',
                                                marginTop: 5,
                                                fontFamily: FONT_BOLD,
                                                fontSize: 13,
                                            }}
                                        >
                                            {t('teaser')}
                                        </Text>
                                    </Pressable>
                                )}
                            {event.fileList.getList().length > 0 && (
                                <Pressable
                                    style={{
                                        justifyContent: 'center',
                                        marginHorizontal: 10,
                                        alignItems: 'center',
                                        marginBottom: 10,
                                    }}
                                    onPress={() => {
                                        props.navigation.navigate(
                                            'DocumentEventModal',
                                            {
                                                event,
                                            },
                                        );
                                    }}
                                >
                                    <PastilleView
                                        color={getColor('secondary')}
                                        size={55}
                                        icon={
                                            <DocumentIcon
                                                height={35}
                                                width={35}
                                                fill={'white'}
                                            />
                                        }
                                    />
                                    <Text
                                        numberOfLines={1}
                                        adjustsFontSizeToFit
                                        style={{
                                            textAlign: 'center',

                                            color: 'white',
                                            marginTop: 5,
                                            fontFamily: FONT_BOLD,
                                            fontSize: 13,
                                        }}
                                    >
                                        {t('files')}
                                    </Text>
                                </Pressable>
                            )}
                            <Pressable
                                style={{
                                    marginHorizontal: 10,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: 10,
                                }}
                                onPress={() => {
                                    props.navigation.navigate('Contact');
                                }}
                            >
                                <PastilleView
                                    color={getColor('secondary')}
                                    size={55}
                                    icon={
                                        <PhoneIcon
                                            height={32}
                                            width={32}
                                            fill={'white'}
                                        />
                                    }
                                />
                                <Text
                                    numberOfLines={1}
                                    adjustsFontSizeToFit
                                    style={{
                                        textAlign: 'center',
                                        color: 'white',
                                        marginTop: 5,
                                        fontFamily: FONT_BOLD,
                                        fontSize: 13,
                                    }}
                                >
                                    {t('contacts')}
                                </Text>
                            </Pressable>
                        </View>
                    </View>

                    {(event.video.get() ?? '') !== '' &&
                        showVideo &&
                        !loading && (
                            <View
                                style={{
                                    marginTop: 20,
                                    marginHorizontal: 20,
                                }}
                            >
                                {/*<YouTube
                                    apiKey={YOUTUBE_API}
                                    videoId={event.video.get()} // The YouTube video ID
                                    fullscreen
                                    style={{
                                        height: ((width() - 40) * 9) / 16,
                                        borderRadius: 20,
                                        width: width() - 40,
                                    }}
                                />*/}
                            </View>
                        )}

                    {/*<HeartSection event={event}/>*/}

                    {!loading ? (
                        <View
                            style={{
                                paddingTop:
                                    event.advertList.getList().length > 0
                                        ? 0
                                        : HEADER_BONUS_HEIGHT + 20,
                            }}
                        >
                            {
                                <AdvertSection
                                    event={event}
                                    navigation={props.navigation}
                                />
                            }
                            <HeartSection
                                event={event}
                                navigation={props.navigation}
                            />

                            {nextOrCurrentEvent.length > 0 && (
                                <View
                                    style={{
                                        marginHorizontal: 20,
                                        marginTop: -10,
                                    }}
                                >
                                    <View
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flexDirection: 'row',
                                            marginBottom: 15,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontSize: 18,
                                                fontFamily: FONT_BOLD,
                                                color: getColor('text'),
                                            }}
                                        >
                                            {nextOrCurrentEvent[0].isNow()
                                                ? t('now')
                                                : t('comming_next')}
                                        </Text>

                                        {!nextOrCurrentEvent[0].isNow() && (
                                            <TimerView
                                                date={
                                                    new Date(
                                                        nextOrCurrentEvent[0].getBetterDate() ??
                                                            '',
                                                    )
                                                }
                                            />
                                        )}
                                    </View>
                                    {!nextOrCurrentEvent[0].isNow() && (
                                        <View
                                            style={{
                                                marginBottom: 10,
                                            }}
                                        >
                                            <CountDownView
                                                date={
                                                    new Date(
                                                        nextOrCurrentEvent[0].getBetterDate() ??
                                                            '',
                                                    )
                                                }
                                            />
                                        </View>
                                    )}
                                    {!nextOrCurrentEvent[0].isNow() && (
                                        <View
                                            style={{
                                                marginTop: 5,
                                                marginBottom: 20,
                                            }}
                                        >
                                            <ProgressLine />
                                        </View>
                                    )}
                                    {nextOrCurrentEvent.map(
                                        (e: EventTiny, i: number) => {
                                            return (
                                                <ScheduleEvent
                                                    navigation={
                                                        props.navigation
                                                    }
                                                    event={e}
                                                    key={e.getJTOIdentifier()}
                                                    last={
                                                        i ===
                                                        nextOrCurrentEvent.length -
                                                            1
                                                    }
                                                />
                                            );
                                        },
                                    )}
                                </View>
                            )}
                            {event.asAbout() && (
                                <Text
                                    style={{
                                        color: getColor('text'),
                                        fontFamily: FONT_BOLD,
                                        fontSize: 20,
                                        paddingHorizontal: 20,
                                        marginBottom: 20,
                                    }}
                                >
                                    {t('event.description')}
                                </Text>
                            )}
                            {(event.asAddress() || event.asDate()) && (
                                <InfoEventView event={event} />
                            )}
                            {event.asDescription() && (
                                <View
                                    style={{
                                        height: 10,
                                    }}
                                ></View>
                            )}
                            {event.asDescription() && (
                                <View
                                    style={{
                                        marginTop: -10,
                                        paddingHorizontal: 20,
                                    }}
                                >
                                    <CustomTextWithLinks
                                        content={event.description.get() ?? ''}
                                        style={{
                                            color: getColor('text'),
                                            fontFamily: FONT_REGULAR,
                                        }}
                                        globalStyle={{
                                            marginTop: 10,
                                        }}
                                    />
                                </View>
                            )}
                            <View
                                style={{
                                    height: 30,
                                }}
                            ></View>
                            {pastSubEvent.length > 0 && (
                                <View
                                    style={{
                                        margin: 20,
                                        marginTop: -10,
                                    }}
                                >
                                    <View
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flexDirection: 'row',
                                            marginBottom: 15,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontSize: 18,
                                                fontFamily: FONT_BOLD,
                                                color: getColor('text'),
                                            }}
                                        >
                                            {t('older_event')}
                                        </Text>
                                        <Text
                                            style={{
                                                fontSize: 18,
                                                fontFamily: FONT_BOLD,
                                                color: getColor('text'),
                                            }}
                                        >
                                            {pastSubEvent.length}{' '}
                                            {pastSubEvent.length > 1
                                                ? t(
                                                      'events',
                                                  ).toLocaleLowerCase()
                                                : t(
                                                      'event',
                                                  ).toLocaleLowerCase()}
                                        </Text>
                                    </View>
                                    {pastSubEvent.map(
                                        (e: EventTiny, i: number) => {
                                            return (
                                                <ScheduleEvent
                                                    navigation={
                                                        props.navigation
                                                    }
                                                    event={e}
                                                    key={e.getJTOIdentifier()}
                                                    last={
                                                        i ===
                                                        pastSubEvent.length - 1
                                                    }
                                                />
                                            );
                                        },
                                    )}
                                </View>
                            )}
                        </View>
                    ) : (
                        <View
                            style={{
                                height: height() * 0.6,
                                width: width(),
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    )}
                </ScrollView>
            </Header>
            {event.dirigeant.get() !== null &&
                (event.word.get() ?? '') !== '' &&
                newMessage && (
                    <Pressable
                        style={{
                            position: 'absolute',
                            bottom: NAVIGATION_BAR_HEIGHT + 20,
                            right: 20,
                            display: 'flex',
                            justifyContent: 'center',
                            paddingHorizontal: 5,
                            paddingRight: 20,
                            borderRadius: 50,
                            alignItems: 'center',
                            flexDirection: 'row',
                            backgroundColor: getColor('secondary'),
                        }}
                        onPress={() => {
                            setNewMessage(false);
                            /*storage.setBool(
                                'dirigeantword' + event.getJTOIdentifier(),
                                true,
                            );*/
                            props.navigation.navigate('WordPage', {
                                event,
                            });
                        }}
                    >
                        {/*<LottieView
                            style={{
                                width: 50,
                                height: 50,
                            }}
                            source={chatAnim}
                            autoPlay
                            loop={true}
                        />*/}
                        <Lottie
                            style={{
                                width: 50,
                                height: 50,
                            }}
                            autoPlay
                            loop={true}
                            animationData={chatAnim}
                        />
                        <Text
                            adjustsFontSizeToFit
                            numberOfLines={1}
                            style={{
                                fontFamily: FONT_BOLD,
                                fontSize: 20,
                                color: 'white',
                            }}
                        >
                            {t('new_message')}
                        </Text>
                    </Pressable>
                )}
        </View>
    );
};
