import { get, getRestore, post } from '../utils/http';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ActivityIndicator,
    Pressable,
    Text,
    TextInput,
    View,
} from 'react-native';
import React from 'react';
import { MpInput } from '../base/MpInput';
import User from '../object/User';
import { styles } from '../utils/styles';
import { marginBottom, marginTop } from '../utils/tailwinds';
import {
    APP_EVENT_ID,
    APP_NAME,
    APP_PUBLIC_EVENT_ID,
    getColor,
} from '../utils/theme';
import { height, width } from '../utils/utils';
import { HTTPUtils } from 'mp-ts-http';
import { getErrorMessage } from 'mp-ts-error';
import { EyeIcon, EyeSlashIcon } from 'react-native-heroicons/solid';
import { Circle, Svg, SvgUri } from 'react-native-svg';
import Ge from '../svg/Ge';
import Cartouche from '../svg/Cartouche';
import { NAVIGATION_BAR_HEIGHT, STATUS_BAR_HEIGHT } from '../utils/screen';
//import { ScrollView } from 'react-native-gesture-handler';
import BgGe from '../svg/BgGe';
import { GeSpinner } from '../component/GeSpinner';
import { HeaderSimplePage } from '../component/HeaderSimplePage';
import { CartoucheButton } from '../component/CartoucheButton';
import { ContentCartouche } from '../component/ContentCartouche';
import { Header } from '../component/Header';
import Logo from '../svg/Logo';
import { CommonActions } from '@react-navigation/native';
import { SERVER_URL } from '../utils/constants';
import { nextLogin } from './LoginPage';
import { BEFORE_LOGO } from '../utils/config';
import { applyResult } from '../utils/applyResult';

interface IInscripPageProps {
    navigation: any;
}

export const InscripPage = (props: IInscripPageProps) => {
    const [email, setEmail] = useState<string>('');
    const [firstname, setFirstname] = useState<string>('');
    const [lastname, setLastname] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    //const scrollRef = useRef<any>(null);

    const { t } = useTranslation();
    User.translation = t;

    useEffect(() => {
        setEmail('');
        setFirstname('');
        setLastname('');
        setPassword('');
        setShowPassword(false);
        setMessage('');
        setLoading(false);
    }, [props.navigation.isFocused()]);

    /*const scrollToBottom = () => {
        scrollRef.current.scrollToEnd({ animated: true });
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        });
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 50);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 100);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 150);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 200);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 250);
    };*/

    const onSelectEvent = () => {
        User._instance!.event = APP_PUBLIC_EVENT_ID.toString() ?? '';
        User._instance!.loadEvent();
        localStorage.setItem('event', User._instance!.event + '');

        get('/user/event/get', {
            id: User._instance!.event ?? '',
        }).then((res) => {
            console.log(res);
            if (HTTPUtils.isSuccess(res)) {
                applyResult(HTTPUtils.getResult(res), User._instance!, true);
                User._instance!.loadEvent();
                if (User._instance.getEvent()) {
                    props.navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [{ name: 'Tab' }],
                        }),
                    );
                } else {
                    props.navigation.navigate('Error', {
                        title: 'no_permission_title',
                        description: 'no_permission',
                    });
                }
            } else {
                props.navigation.navigate('Error', {
                    title: 'no_permission_title',
                    description: 'no_permission',
                });
            }
        });
    };

    const onLogin = () => {
        setLoading(true);
        User._instance = new User();
        post(
            '/user/register',
            {
                identifier: email,
                password,
                lastname,
                firstname,
                id: APP_PUBLIC_EVENT_ID,
            },
            true,
        )
            .then((res) => {
                if (HTTPUtils.isSuccess(res)) {
                    setMessage('');
                    const data = HTTPUtils.getResult(res);
                    User._instance?.login(data);
                    onSelectEvent();

                    /*if (data.noAccess === true) {
                        props.navigation.dispatch(
                            CommonActions.reset({
                                index: 0,
                                routes: [{ name: 'Event' }],
                            }),
                        );
                    } else {
                        nextLogin(
                            props.navigation,
                            setMessage,
                            setLoading,
                            t,
                            APP_PUBLIC_EVENT_ID,
                        );
                    }*/
                } else {
                    setMessage(t(HTTPUtils.getError(res)) ?? '');
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                setMessage(t('went_wrong_no_internet') ?? '');
            });
    };

    return (
        <View style={[styles.page]}>
            <Header
                title={APP_NAME}
                beforeLogo={BEFORE_LOGO}
                fallback={
                    <Logo
                        height={40}
                        width={40}
                    />
                }
                fallbackColor={'white'}
                backButton={true}
                navigation={props.navigation}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                full
                logo={''}
            >
                <View
                    //ref={scrollRef}
                    style={{}}
                    // @ts-ignore
                    automaticallyAdjustKeyboardInsets={true}
                >
                    <View
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flex: 1,
                            marginRight: width() * 0.15,
                            marginLeft: width() * 0.15,
                            width: width() * 0.7,
                        }}
                    >
                        <HeaderSimplePage
                            color={getColor('ge')}
                            title={t('connection_page_title')}
                            description={t('connection_page_description')}
                        />

                        {loading ? (
                            <View style={[styles.boxCenter, marginTop(75)]}>
                                <GeSpinner color={getColor('ge')} />
                            </View>
                        ) : (
                            <View
                                style={[
                                    styles.box,
                                    {
                                        width: width(),
                                    },
                                ]}
                            >
                                <View
                                    style={{
                                        paddingHorizontal: width() * 0.1,
                                        paddingVertical: width() * 0.1,
                                    }}
                                >
                                    <MpInput
                                        autoCapitalize='none'
                                        value={firstname}
                                        onFocus={() => {
                                            // Scroll to bottom
                                            //scrollToBottom();
                                        }}
                                        onChangeText={(text) => {
                                            setFirstname(text);
                                        }}
                                        defaultValue={firstname}
                                        keyboardType={'default'}
                                        textContentType='givenName'
                                        placeholder={t('firstname') as string}
                                        style={styles.input}
                                        selectionColor={getColor('ge')}
                                    />

                                    <View style={[marginTop(20)]}>
                                        <MpInput
                                            autoCapitalize='none'
                                            value={lastname}
                                            onFocus={() => {
                                                // Scroll to bottom
                                                //scrollToBottom();
                                            }}
                                            onChangeText={(text) => {
                                                setLastname(text);
                                            }}
                                            defaultValue={lastname}
                                            keyboardType={'default'}
                                            textContentType='familyName'
                                            placeholder={
                                                t('lastname') as string
                                            }
                                            style={styles.input}
                                            selectionColor={getColor('ge')}
                                        />
                                    </View>
                                    <View style={[marginTop(20)]}>
                                        <MpInput
                                            autoCapitalize='none'
                                            value={email}
                                            onFocus={() => {
                                                // Scroll to bottom
                                                //scrollToBottom();
                                            }}
                                            onChangeText={(text) => {
                                                setEmail(text);
                                            }}
                                            defaultValue={email}
                                            keyboardType={'email-address'}
                                            textContentType='emailAddress'
                                            placeholder={t('email') as string}
                                            style={styles.input}
                                            selectionColor={getColor('ge')}
                                        />
                                    </View>
                                    <View style={[marginTop(20)]}>
                                        <MpInput
                                            rightOnPress={() => {
                                                setShowPassword(!showPassword);
                                            }}
                                            rightIcon={
                                                showPassword ? (
                                                    <EyeIcon
                                                        color={getColor('hint')}
                                                    />
                                                ) : (
                                                    <EyeSlashIcon
                                                        color={getColor('hint')}
                                                    />
                                                )
                                            }
                                            secureTextEntry={!showPassword}
                                            value={password}
                                            onChangeText={(text) => {
                                                setPassword(text);
                                            }}
                                            defaultValue={password}
                                            autoCapitalize='none'
                                            keyboardType={'default'}
                                            textContentType='password'
                                            placeholder={
                                                t('password') as string
                                            }
                                            style={styles.input}
                                            selectionColor={getColor('ge')}
                                            onFocus={() => {
                                                // Scroll to bottom
                                                //scrollToBottom();
                                            }}
                                        />
                                    </View>
                                    {message !== '' && (
                                        <Text
                                            style={[
                                                styles.textError,
                                                marginTop(25),
                                            ]}
                                        >
                                            {message}
                                        </Text>
                                    )}
                                </View>

                                <CartoucheButton
                                    onPress={() => {
                                        onLogin();
                                    }}
                                    color={getColor('ge')}
                                    title={t('signin')}
                                    height={70}
                                />

                                <Pressable
                                    onPress={() => {
                                        props.navigation.goBack();
                                    }}
                                >
                                    <Text
                                        style={[
                                            styles.text,
                                            {
                                                textAlign: 'center',
                                                marginTop: 25,
                                                marginBottom: 10,
                                                color: getColor('ge'),
                                            },
                                        ]}
                                    >
                                        {t('connec_account')}
                                    </Text>
                                </Pressable>
                            </View>
                        )}
                    </View>
                </View>
            </Header>
        </View>
    );
};
