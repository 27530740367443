import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ActivityIndicator,
    Image,
    Platform,
    Pressable,
    RefreshControl,
    ScrollView,
    SectionList,
    Text,
    View,
} from 'react-native';
import {
    EyeIcon,
    MagnifyingGlassIcon,
    PaperAirplaneIcon,
    PlusIcon,
    UserGroupIcon,
    UserIcon,
} from 'react-native-heroicons/solid';
import User from '../object/User';
import {
    HEADER_BONUS_HEIGHT,
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import { styles } from '../utils/styles';
import { marginBottom, marginTop } from '../utils/tailwinds';
import { FONT_REGULAR, getColor } from '../utils/theme';
import {
    height,
    isCloseToBottom,
    mustLoginButton,
    width,
} from '../utils/utils';
import { IPageProps } from './IPageProps';
import TextInput from 'react-native-text-input-interactive';
import { MpInput } from '../base/MpInput';
import { SERVER_URL } from '../utils/constants';
import { Keyboard } from 'react-native';
import React from 'react';
import { Message } from '../object/Message';
import ChatGroup from '../object/ChatGroup';
import { GeSpinner } from '../component/GeSpinner';
import { Header } from '../component/Header';
import { PastilleView } from '../component/PastilleView';
import { ChatView } from '../component/ChatView';
import { HTTPUtils } from 'mp-ts-http';
import { MessageTiny } from '../object/compressed/chat/MessageTiny';
import ChatGroupTiny from '../object/compressed/chat/ChatGroupTiny';
import UserChat from '../object/compressed/chat/UserChat';
import useJTOState from '../utils/jto/useJTOState';
import { applyResult } from '../utils/applyResult';

export const ChatPage = (props: IPageProps) => {
    const { t } = useTranslation();
    User.translation = t;

    const [search, setSearch] = useState('');

    const user = useJTOState(User._instanceChat);
    user.setJTOIdentifier(User._instance.getJTOIdentifier());

    const chatList = useJTOState(user.chatList);
    const [refreshing, setRefreshing] = useState(false);

    const onRefresh = () => {
        setRefreshing(false);
        chatList.reset();
        chatList.refetch(
            {
                search,
            },
            (res) => {
                applyResult(
                    HTTPUtils.getResult(res),
                    User._instanceConnect,
                    true,
                );
            },
        );
    };

    useEffect(() => {
        setTimeout(() => {
            if ((User._instance.useMessage.get() ?? true) === true) {
                chatList.fetch(
                    {
                        search,
                    },
                    (res) => {
                        applyResult(
                            HTTPUtils.getResult(res),
                            User._instanceConnect,
                            true,
                        );
                    },
                );
            } else {
                chatList.setList([]);
                chatList.setComplite(true);
                chatList.setLoading(false);
            }
        });
    }, []);

    return (
        <View
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: width(),
                height: '100%',
                margin: 'auto',
                paddingBottom: NAVIGATION_BAR_HEIGHT + TAB_HEIGHT,
            }}
        >
            <Header
                line={1}
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
                title={t('messages')}
                after={
                    <View
                        style={{
                            width: width() - 40,
                            paddingHorizontal: 20,
                            marginTop: 15,
                            marginBottom: 5,
                        }}
                    >
                        <View
                            style={{
                                flexDirection: 'row',
                            }}
                        >
                            <MpInput
                                value={search}
                                leftIcon={
                                    <MagnifyingGlassIcon
                                        size={20}
                                        color={getColor('hint')}
                                    />
                                }
                                onChangeText={(e) => {
                                    setSearch(e);
                                    User._search = e;
                                    chatList.reset();
                                    chatList.refetch(
                                        {
                                            search: e,
                                        },
                                        (res) => {
                                            applyResult(
                                                HTTPUtils.getResult(res),
                                                User._instanceConnect,
                                                true,
                                            );
                                        },
                                    );
                                }}
                                placeholder={t('search_user') ?? ''}
                                style={styles.inputHeader}
                            />
                        </View>
                        <View
                            style={{
                                height: 10,
                            }}
                        ></View>
                    </View>
                }
            >
                <View
                    // @ts-ignore

                    // @ts-ignore
                    onScroll={({ nativeEvent }) => {
                        if (isCloseToBottom(nativeEvent)) {
                            chatList.more(
                                {
                                    search,
                                },
                                (res) => {
                                    applyResult(
                                        HTTPUtils.getResult(res),
                                        User._instanceConnect,
                                        true,
                                    );
                                },
                            );
                        }
                    }}
                    onMomentumScrollEnd={({ nativeEvent }) => {
                        if (isCloseToBottom(nativeEvent)) {
                            chatList.more(
                                {
                                    search,
                                },
                                (res) => {
                                    applyResult(
                                        HTTPUtils.getResult(res),
                                        User._instanceConnect,
                                        true,
                                    );
                                },
                            );
                        }
                    }}
                    scrollEventThrottle={1}
                    keyboardDismissMode='on-drag'
                    style={{
                        marginTop: -HEADER_BONUS_HEIGHT,
                    }}
                    // @ts-ignore
                    refreshControl={
                        <RefreshControl
                            progressViewOffset={HEADER_BONUS_HEIGHT}
                            colors={[getColor('primary')]}
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                        />
                    }
                >
                    <View
                        style={{
                            height: HEADER_BONUS_HEIGHT + 20,
                        }}
                    ></View>
                    {(User._instance.useMessage.get() ?? true) !== true ? (
                        <View
                            style={[
                                styles.boxCenter,
                                {
                                    flex: 1,
                                    height:
                                        height() -
                                        STATUS_BAR_HEIGHT -
                                        NAVIGATION_BAR_HEIGHT -
                                        TAB_HEIGHT -
                                        150,
                                },
                            ]}
                        >
                            <Text
                                style={{
                                    textAlign: 'center',
                                    paddingHorizontal: 20,
                                    fontFamily: FONT_REGULAR,
                                }}
                            >
                                {t('chat_blocked')}
                            </Text>
                        </View>
                    ) : (
                        <>
                            {chatList
                                .getList()
                                .map((item: MessageTiny | ChatGroupTiny) => {
                                    return (
                                        <ChatView
                                            key={item.getJTOIdentifier()}
                                            item={item}
                                            navigation={props.navigation}
                                        />
                                    );
                                })}
                            {(chatList.isLoading() ||
                                !chatList.isComplite()) && (
                                <View
                                    style={[
                                        styles.boxCenter,
                                        {
                                            paddingBottom: 50,
                                        },
                                        chatList.size() === 0
                                            ? {
                                                  flex: 1,
                                                  height:
                                                      height() -
                                                      STATUS_BAR_HEIGHT -
                                                      NAVIGATION_BAR_HEIGHT -
                                                      TAB_HEIGHT -
                                                      150,
                                              }
                                            : marginTop(50),
                                    ]}
                                >
                                    <GeSpinner color={getColor('primary')} />
                                </View>
                            )}
                            {chatList.getList().length === 0 &&
                                !chatList.isLoading() &&
                                chatList.isComplite() && (
                                    <View
                                        style={[
                                            styles.boxCenter,
                                            {
                                                flex: 1,
                                                height:
                                                    height() -
                                                    STATUS_BAR_HEIGHT -
                                                    NAVIGATION_BAR_HEIGHT -
                                                    TAB_HEIGHT -
                                                    150,
                                            },
                                        ]}
                                    >
                                        <Text
                                            style={{
                                                textAlign: 'center',
                                                fontFamily: FONT_REGULAR,
                                                color: getColor('text'),
                                            }}
                                        >
                                            {t('no_chat_found')}
                                        </Text>
                                        <Pressable
                                            style={[
                                                {
                                                    backgroundColor:
                                                        getColor('secondary'),
                                                    paddingVertical: 10,
                                                    paddingHorizontal: 14,
                                                    borderRadius: 10,
                                                },
                                                {
                                                    backgroundColor:
                                                        getColor('secondary'),
                                                },
                                                { marginTop: 20 },
                                            ]}
                                            onPress={() => {
                                                mustLoginButton(
                                                    props.navigation,
                                                    t,
                                                    () => {
                                                        props.navigation.navigate(
                                                            'MenuAddChatModal',
                                                        );
                                                    },
                                                );
                                            }}
                                        >
                                            <Text style={styles.buttonText}>
                                                {t('create_chat')}
                                            </Text>
                                        </Pressable>
                                    </View>
                                )}
                        </>
                    )}
                    <View
                        style={{
                            height: TAB_HEIGHT + NAVIGATION_BAR_HEIGHT,
                        }}
                    ></View>
                </View>
            </Header>
            {(User._instance.useMessage.get() ?? true) === true && (
                <Pressable
                    style={{
                        position: 'absolute',
                        padding: 10,
                        bottom: 0,
                        right: 10,
                        borderRadius: 1000,
                    }}
                    onPress={() => {
                        mustLoginButton(props.navigation, t, () => {
                            props.navigation.navigate('MenuAddChatModal');
                        });
                    }}
                >
                    <PastilleView
                        color={getColor('secondary')}
                        size={65}
                        icon={
                            <PlusIcon
                                size={50}
                                color={'white'}
                            />
                        }
                    />
                </Pressable>
            )}
        </View>
    );
};
