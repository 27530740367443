import { useEffect, useState } from 'react';
import { Pressable, Text, View } from 'react-native';
import React from 'react';
//import Carousel, { Pagination } from 'react-native-snap-carousel';
import { FONT_BOLD, FONT_MEDIUM, HEART_COLOR, getColor } from '../utils/theme';
import { width } from '../utils/utils';
import Event from '../object/Event';
import useJTOState from '../utils/jto/useJTOState';
import JTOListFetch from '../object/JTOListFetch';
import EventTiny from '../object/compressed/event/EventTiny';
import Post from '../object/Post';
import { GeSpinner } from './GeSpinner';
import { t } from 'i18next';
import { ScheduleContentEvent } from './ScheduleContentEvent';
import { HeartIcon } from 'react-native-heroicons/solid';
import { FavoriteItem } from './FavoriteItem';
import Carousel from 'react-multi-carousel';

interface IHeartSectionProps {
    event: Event;
    navigation: any;
}

export const HeartSection = (props: IHeartSectionProps) => {
    const favoriteList = useJTOState(
        new JTOListFetch<EventTiny | Post>('/event/favorite/list', [
            EventTiny,
            Post,
        ]),
    );

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        favoriteList.fetch({
            id: props.event.getJTOIdentifier(),
        });
        setLoading(false);
    }, []);

    const [activeSlide, setActiveSlide] = useState(0);

    return favoriteList.isLoading() ? (
        <View
            style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <GeSpinner color={getColor('primary')} />
        </View>
    ) : favoriteList.getList().length > 0 ? (
        <View
            style={{
                marginBottom: 20,
            }}
        >
            <Text
                style={{
                    fontSize: 18,
                    fontFamily: FONT_BOLD,
                    color: getColor('text'),
                    marginBottom: 10,
                    marginHorizontal: 20,
                }}
            >
                {t('favorites')}
            </Text>

            <Carousel
                responsive={{
                    mobile: {
                        breakpoint: { max: 3000, min: 0 },
                        items: 1,
                        slidesToSlide: 1, // optional, default to 1.
                    },
                }}
                swipeable
                showDots
                infinite
                autoPlay
                autoPlaySpeed={3000}
                customTransition='all .5'
                dotListClass='custom-dot-list-style'
                transitionDuration={500}
                customLeftArrow={<></>}
                customRightArrow={<></>}
                renderDotsOutside
            >
                {favoriteList.getList().map((item, index) => (
                    <FavoriteItem
                        item={item}
                        navigation={props.navigation}
                    />
                ))}
            </Carousel>
            {/*<Carousel
                data={favoriteList.getList()}
                style={{}}
                renderItem={({ item }) => (
                    <FavoriteItem
                        item={item}
                        navigation={props.navigation}
                    />
                )}
                onSnapToItem={(index) => setActiveSlide(index)}
                sliderWidth={width()}
                itemWidth={width()}
                layout={'default'}
            />
            <Pagination
                dotsLength={favoriteList.getList().length}
                activeDotIndex={activeSlide}
                containerStyle={{
                    margin: 0,
                    padding: 0,
                    marginBottom: -40,
                }}
                dotStyle={{}}
                inactiveDotStyle={{}}
                inactiveDotOpacity={0.4}
                inactiveDotScale={0.6}
            />*/}
        </View>
    ) : (
        <View></View>
    );
};
