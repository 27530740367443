import { get, getRestore, post } from '../utils/http';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ActivityIndicator,
    Pressable,
    Text,
    TextInput,
    View,
} from 'react-native';
import { MpInput } from '../base/MpInput';
import User from '../object/User';
import { styles } from '../utils/styles';
import { marginBottom, marginTop } from '../utils/tailwinds';
import {
    APP_EVENT_ID,
    APP_NAME,
    APP_NEED_PASSWORD,
    APP_PUBLIC_EVENT_ID,
    getColor,
    initTheme,
} from '../utils/theme';
import { height, width } from '../utils/utils';
import { HTTPUtils } from 'mp-ts-http';
import { getErrorMessage } from 'mp-ts-error';
import { EyeIcon, EyeSlashIcon } from 'react-native-heroicons/solid';
import { Circle, Svg, SvgUri } from 'react-native-svg';
import Ge from '../svg/Ge';
import Cartouche from '../svg/Cartouche';
import { NAVIGATION_BAR_HEIGHT, STATUS_BAR_HEIGHT } from '../utils/screen';
//import { ScrollView } from 'react-native-gesture-handler';
import BgGe from '../svg/BgGe';
import { GeSpinner } from '../component/GeSpinner';
import { HeaderSimplePage } from '../component/HeaderSimplePage';
import { CartoucheButton } from '../component/CartoucheButton';
import { ContentCartouche } from '../component/ContentCartouche';
import { Header } from '../component/Header';
import Logo from '../svg/Logo';
import { CommonActions } from '@react-navigation/native';
import { SERVER_URL } from '../utils/constants';
import { BEFORE_LOGO } from '../utils/config';
import { applyResult } from '../utils/applyResult';

interface IConnectionPageProps {
    navigation: any;
}
export const nextLogin = (
    navigation: any,
    setMessage: (message: string) => void,
    setLoading: (loading: boolean) => void,
    t: (key: string) => string,
    id = APP_EVENT_ID,
) => {
    let make = false;
    onSelectEvent(navigation);
};

const onSelectEvent = (navigation: any) => {
    User._instance!.event = APP_PUBLIC_EVENT_ID.toString() ?? '';
    User._instance!.loadEvent();
    localStorage.setItem('event', User._instance!.event + '');

    get('/user/event/get', {
        id: User._instance!.event ?? '',
    }).then((res) => {
        console.log('success', res);
        if (HTTPUtils.isSuccess(res)) {
            applyResult(HTTPUtils.getResult(res), User._instance!, true);
            User._instance!.loadEvent();
            if (User._instance.getEvent()) {
                navigation.dispatch(
                    CommonActions.reset({
                        index: 0,
                        routes: [{ name: 'Tab' }],
                    }),
                );
            } else {
                navigation.navigate('Error', {
                    title: 'no_permission_title',
                    description: 'no_permission',
                });
            }
        } else {
            console.log('error');
            navigation.navigate('Error', {
                title: 'no_permission_title',
                description: 'no_permission',
            });
        }
    });
};

export const LoginPage = (props: IConnectionPageProps) => {
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [forceShowPassword, setForceShowPassword] =
        useState<boolean>(APP_NEED_PASSWORD);
    const [connectPassword, setConnectPassword] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    //const scrollRef = useRef<any>(null);

    const { t } = useTranslation();
    User.translation = t;

    useEffect(() => {
        setEmail('');
        setPassword('');
        setShowPassword(false);
        setConnectPassword(false);
        setMessage('');
        setLoading(false);
    }, [props.navigation.isFocused()]);

    /*const scrollToBottom = () => {
        scrollRef.current.scrollToEnd({ animated: true });
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        });
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 50);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 100);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 150);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 200);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 250);
    };*/

    const onEmailChange = (text: string) => {
        setEmail(text);
        setConnectPassword(false);
        post(
            '/user/password/is_init',
            {
                email: text,
            },
            true,
        )
            .then((res) => {
                if (HTTPUtils.isSuccess(res)) {
                    const result = HTTPUtils.getResult(res);
                    if (result.password_init === true) {
                        setConnectPassword(true);
                    } else {
                        setConnectPassword(false);
                    }
                }
            })
            .catch((err) => {
                setConnectPassword(false);
            });
    };

    const onLogin = () => {
        setLoading(true);
        User._instance = new User();
        if (forceShowPassword) {
            post(
                '/user/login_event',
                {
                    identifier: email,
                    event: APP_EVENT_ID,
                    password,
                },
                true,
            )
                .then((res) => {
                    if (HTTPUtils.isSuccess(res)) {
                        setMessage('');

                        const data = HTTPUtils.getResult(res);
                        User._instance?.login(data);

                        nextLogin(props.navigation, setMessage, setLoading, t);
                    } else {
                        setLoading(false);
                        setMessage(t(HTTPUtils.getError(res)) ?? '');
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    setMessage(t('went_wrong_no_internet') ?? '');
                });
        } else if (!connectPassword) {
            post(
                '/user/login_no_password',
                {
                    identifier: email,
                },
                true,
            )
                .then((res) => {
                    if (HTTPUtils.isSuccess(res)) {
                        setMessage('');
                        User._email = email;
                        props.navigation.dispatch(
                            CommonActions.reset({
                                index: 0,
                                routes: [{ name: 'Validate' }],
                            }),
                        );
                    } else {
                        setLoading(false);
                        setMessage(t(HTTPUtils.getError(res)) ?? '');
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    setMessage(t('went_wrong_no_internet') ?? '');
                });
        } else {
            post(
                '/user/login',
                {
                    identifier: email,
                    password,
                },
                true,
            )
                .then((res) => {
                    if (HTTPUtils.isSuccess(res)) {
                        setMessage('');
                        const data = HTTPUtils.getResult(res);
                        User._instance?.login(data);

                        nextLogin(props.navigation, setMessage, setLoading, t);
                    } else {
                        setLoading(false);
                        setMessage(t(HTTPUtils.getError(res)) ?? '');
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    setMessage(t('went_wrong_no_internet') ?? '');
                });
        }
    };

    return (
        <View style={[styles.page]}>
            <Header
                title={APP_NAME}
                beforeLogo={BEFORE_LOGO}
                backButton
                navigation={props.navigation}
                fallback={
                    <Logo
                        height={40}
                        width={40}
                    />
                }
                fallbackColor={'white'}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                full
                logo={''}
            >
                <View
                    style={{}}
                    //ref={scrollRef}
                >
                    <View
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flex: 1,
                            marginRight: width() * 0.15,
                            marginLeft: width() * 0.15,
                            width: width() * 0.7,
                        }}
                    >
                        <HeaderSimplePage
                            color={getColor('ge')}
                            title={t('connection_page_title')}
                            description={t('connection_page_description')}
                        />

                        {loading ? (
                            <View style={[styles.boxCenter, marginTop(75)]}>
                                <GeSpinner color={getColor('ge')} />
                            </View>
                        ) : (
                            <View
                                style={[
                                    styles.box,
                                    {
                                        width: width(),
                                    },
                                ]}
                            >
                                <View
                                    style={{
                                        paddingHorizontal: width() * 0.1,
                                        paddingVertical: width() * 0.1,
                                    }}
                                >
                                    <MpInput
                                        autoCapitalize='none'
                                        value={email}
                                        onFocus={() => {
                                            // Scroll to bottom
                                            //scrollToBottom();
                                        }}
                                        onChangeText={(text) => {
                                            onEmailChange(text);
                                        }}
                                        defaultValue={email}
                                        keyboardType={'email-address'}
                                        textContentType='emailAddress'
                                        placeholder={t('email') as string}
                                        style={styles.input}
                                        selectionColor={getColor('ge')}
                                    />
                                    {(connectPassword || forceShowPassword) && (
                                        <View style={[marginTop(20)]}>
                                            <MpInput
                                                rightOnPress={() => {
                                                    setShowPassword(
                                                        !showPassword,
                                                    );
                                                }}
                                                rightIcon={
                                                    showPassword ? (
                                                        <EyeIcon
                                                            color={getColor(
                                                                'hint',
                                                            )}
                                                        />
                                                    ) : (
                                                        <EyeSlashIcon
                                                            color={getColor(
                                                                'hint',
                                                            )}
                                                        />
                                                    )
                                                }
                                                secureTextEntry={!showPassword}
                                                value={password}
                                                onChangeText={(text) => {
                                                    setPassword(text);
                                                }}
                                                defaultValue={password}
                                                autoCapitalize='none'
                                                keyboardType={'default'}
                                                textContentType='password'
                                                placeholder={
                                                    t('password') as string
                                                }
                                                style={styles.input}
                                                selectionColor={getColor('ge')}
                                                onFocus={() => {
                                                    // Scroll to bottom
                                                    //scrollToBottom();
                                                }}
                                            />
                                        </View>
                                    )}
                                    {message !== '' && (
                                        <Text
                                            style={[
                                                styles.textError,
                                                marginTop(25),
                                            ]}
                                        >
                                            {message}
                                        </Text>
                                    )}
                                </View>

                                <CartoucheButton
                                    onPress={() => {
                                        onLogin();
                                    }}
                                    color={getColor('ge')}
                                    title={t('connection')}
                                    height={70}
                                />

                                <Pressable
                                    onPress={() => {
                                        props.navigation.navigate('Forget');
                                    }}
                                >
                                    <Text
                                        style={[
                                            styles.text,
                                            {
                                                textAlign: 'center',
                                                marginTop: 25,
                                                color: getColor('ge'),
                                            },
                                        ]}
                                    >
                                        {t('forget_title')}
                                    </Text>
                                </Pressable>

                                <Pressable
                                    onPress={() => {
                                        props.navigation.navigate('Inscrip');
                                    }}
                                >
                                    <Text
                                        style={[
                                            styles.text,
                                            {
                                                textAlign: 'center',
                                                marginTop: 25,
                                                color: getColor('ge'),
                                            },
                                        ]}
                                    >
                                        {t('create_account')}
                                    </Text>
                                </Pressable>
                            </View>
                        )}
                    </View>
                </View>
            </Header>
        </View>
    );
};
