import { t } from 'i18next';
import { Pressable, Text, View } from 'react-native';
import React from 'react';
import {
    BeakerIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from 'react-native-heroicons/solid';
import LinearGradient from 'react-native-linear-gradient';
import User from '../object/User';
import { getMarginLeft, getMarginRight } from '../pages/BadgePage';
import { FONT_BOLD, FONT_MEDIUM, FONT_REGULAR, getColor } from '../utils/theme';
import { width } from '../utils/utils';
import GenericAchivement from './achivement/GenericAchivement';
import { TinyBadge } from './TinyBadge';
import useJTOState from '../utils/jto/useJTOState';

export interface ILevelFocusViewProps {
    user: User;
    navigation: any;
    shadow?: boolean;
    nested?: boolean;
}

export const getXpLevel = (level: number) => {
    return 200 * level + 5 * level * level;
};

export const getCurrentLevel = (xp: number) => {
    let level = 0;
    while (xp > getXpLevel(level + 1)) {
        level++;
    }
    return level;
};

export const getXpText = (xp: number) => {
    // If xp lowert then 1000
    // show xp
    // if xp lower then 1000000
    // show k
    // if xp lower then 1000000000
    // show M
    // if xp lower then 1000000000000
    // show B
    // if xp lower then 1000000000000000
    // show T
    // if xp lower then 1000000000000000000
    // show Q
    // if xp lower then 1000000000000000000000
    // show Qi
    // if xp lower then 1000000000000000000000000
    // show Sx

    if (xp < 1000) {
        return xp.toString();
    }
    if (xp < 1000000) {
        return (xp / 1000).toFixed(1) + ' k';
    }
    if (xp < 1000000000) {
        return (xp / 1000000).toFixed(1) + ' M';
    }
    if (xp < 1000000000000) {
        return (xp / 1000000000).toFixed(1) + ' B';
    }
    if (xp < 1000000000000000) {
        return (xp / 1000000000000).toFixed(1) + ' T';
    }
    if (xp < 1000000000000000000) {
        return (xp / 1000000000000000).toFixed(1) + ' Q';
    }
    if (xp < 1000000000000000000000) {
        return (xp / 1000000000000000000).toFixed(1) + ' Qi';
    }
    if (xp < 1000000000000000000000000) {
        return (xp / 1000000000000000000000).toFixed(1) + ' Sx';
    }
    return xp.toString();
};

export const LevelFocusView = (props: ILevelFocusViewProps) => {
    const { shadow = false, nested = true } = props;

    const user = useJTOState(props.user);
    /*
    <LinearGradient
                start={{x: 0.0, y: 0.5}} end={{x: 0.5, y: 1.0}}
                style={{
                    width: width() - 40,
                    marginHorizontal: 20,
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    borderRadius: 25,
                }}
                colors={[
                    getColor("primary"),
                    getColor("primary")+"C0"
                ]}>
*/
    const level = getCurrentLevel(user.xp.get() ?? 0);

    const badgeListSort = User._badgeList
        .getList()
        .filter((a) => {
            return a.getRemaining(User._instance) > 0;
        })
        .sort((a, b) => {
            if (
                a.getRemaining(User._instance) < b.getRemaining(User._instance)
            ) {
                return -1;
            } else if (
                a.getRemaining(User._instance) > b.getRemaining(User._instance)
            ) {
                return 1;
            } else {
                return 0;
            }
        });

    return (
        <Pressable
            onPress={() => {
                if (!shadow && user.isMe() && nested) {
                    props.navigation?.navigate('BadgePage');
                }
            }}
        >
            <View
                style={{
                    width: width() - 40,
                    marginHorizontal: 20,
                    paddingHorizontal: 20,
                    paddingVertical: 20,
                    borderRadius: 25,
                    marginTop: 10,
                    backgroundColor: getColor('primary'),
                }}
            >
                {shadow && (
                    <Text
                        style={{
                            color: 'white',
                            fontFamily: FONT_BOLD,
                            fontSize: 20,
                            marginBottom: 10,
                        }}
                    >
                        {t('congrat_more_xp')}
                    </Text>
                )}

                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: 10,
                    }}
                >
                    <Text
                        style={{
                            color: 'white',
                            fontFamily: FONT_MEDIUM,
                            fontSize: 20,
                            flex: 1,
                        }}
                    >
                        {t('level', { nb: level + 1 })}
                    </Text>
                    <View
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                            paddingLeft: 12,
                            paddingRight: 8,
                            paddingVertical: 4,
                            borderRadius: 30,
                            backgroundColor: getColor('secondary'),
                        }}
                    >
                        <Text
                            style={{
                                color: 'white',
                                fontFamily: FONT_MEDIUM,
                                fontSize: 14,
                                marginRight: 5,
                            }}
                        >
                            {getXpText(user.xp.get() ?? 0)}
                        </Text>
                        <BeakerIcon
                            color={'white'}
                            height={18}
                            width={18}
                        />
                    </View>
                </View>
                {
                    <View
                        style={{
                            margin: 4,
                            height: 10,
                            borderRadius: 15,
                            position: 'relative',
                            overflow: 'hidden',
                            backgroundColor: 'white',
                        }}
                    >
                        <View
                            style={{
                                height: 10,
                                borderRadius: 15,
                                backgroundColor: getColor('primary') + '50',
                            }}
                        ></View>
                        <View
                            style={{
                                height: 10,
                                position: 'absolute',
                                width:
                                    ((width() - 40 - 40) *
                                        ((user.xp.get() ?? 0) -
                                            getXpLevel(level))) /
                                    (getXpLevel(level + 1) - getXpLevel(level)),
                                backgroundColor: getColor('secondary'),
                            }}
                        ></View>
                    </View>
                }
                {
                    <View
                        style={{
                            display: 'flex',
                            marginTop: 5,
                            marginBottom: 5,

                            position: 'relative',
                            flexDirection: 'row',
                        }}
                    >
                        <Text
                            style={{
                                color: 'white',
                                fontSize: 12,
                                fontFamily: FONT_REGULAR,
                            }}
                        >
                            {getXpText(getXpLevel(level))}
                        </Text>
                        <View
                            style={{
                                flex: 1,
                            }}
                        ></View>
                        <Text
                            style={{
                                color: 'white',
                                fontSize: 12,
                                fontFamily: FONT_REGULAR,
                            }}
                        >
                            {getXpText(getXpLevel(level + 1))}
                        </Text>
                    </View>
                }
                {!shadow && badgeListSort.length > 0 && user.isMe() && (
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            marginHorizontal: -10,
                            marginTop: 0,
                            marginBottom: !shadow && !nested ? -10 : 0,
                        }}
                    >
                        {badgeListSort.slice(0, 3).map((badge, index) => {
                            return (
                                <TinyBadge
                                    key={badge.getJTOIdentifier()}
                                    badge={badge}
                                    index={index}
                                    navigation={props.navigation}
                                />
                            );
                        })}
                    </View>
                )}
                {!shadow && nested && user.isMe() && (
                    <View
                        style={{
                            width: '100%',
                            marginTop: 10,
                            marginBottom: -5,
                            alignContent: 'flex-start',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Text
                            style={{
                                color: 'white',
                                fontFamily: FONT_MEDIUM,
                                textAlign: 'right',
                            }}
                        >
                            {t('view_badge')}
                        </Text>
                        <ChevronRightIcon color={'white'} />
                    </View>
                )}
                {shadow && (
                    <Text
                        style={{
                            color: 'white',
                            fontFamily: FONT_REGULAR,
                            fontSize: 14,
                        }}
                    >
                        {t('keep_xp')}
                    </Text>
                )}
            </View>
        </Pressable>
    );
};
